import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5AngularGauge} from "../../../../global/plot/E5AngularGauge";
import {E5MainConfig} from "../../../../global/E5MainConfig";
import {E5StoreCBSys} from "../../../../store/E5StoreCBSys";
import {E5UtilI18n} from "../../../../global/E5MainLang";
import {E5StoreCB} from "../../../../store/E5StoreCB";
import {E5Store} from "../../../../store/E5Store";
import {E5CBDashboard} from "../E5CBDashboard";
//
import "./E5CBSysFlash.css";

//E5
interface E5CBSysFlashState {}

//E5
interface E5CBSysFlashProps {
	toasterref: React.RefObject<BP.Toaster>;
	downloadref: React.RefObject<BP.Button>;
	percent: boolean;
	togglefunc: () => void;
}

//E5
export const E5CBSysFlash = observer(class E5CBSysFlash extends React.PureComponent
	<E5CBSysFlashProps, E5CBSysFlashState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let {_} = E5UtilI18n, {status, health, flusaequips, flusadetails, flcorequips, flcordetails} =
				E5StoreCBSys.Ins().flash, {loading} = status, {nilistsettings} = E5StoreCB.Ins(),
			{mode, cla, eqptype, sofv, model} = nilistsettings, {
				RenderPiePopover, DownloadNiListEquip, RenderEquipsPie, PieClickEquip1, PieClickEquip2,
				DownloadNiListDetail, RenderDetailsPie, PieClickDetail1, PieClickDetail2, RenderChart
			} = E5CBDashboard;

		return <div className="e5cb-sys-flash e5column-20">
			<div className="e5line-20">
				<div className="e5linefull">
					<E5AngularGauge
						gaugeinfo={{value: health, label: _("system-flash"), title: E5UtilI18n._("cb-sys-flash-health-score"), loading}} isNewComponent chartOption={{type:'gauge'}}/>
				</div>
				<div className="e5linefull">
					<div className="e5line-0">
						{RenderPiePopover(mode === "equip1", this.props.downloadref,
							() => DownloadNiListEquip("flashUsage", eqptype, cla, this.props.toasterref),
							() => E5StoreCB.Ins().SetNiListSettings("none", "", undefined,
								undefined, undefined, undefined, undefined, undefined,
								undefined),
							RenderEquipsPie("flash", flusaequips, loading, PieClickEquip1, "flusa"))}
					</div>
				</div>
				<div className="e5linefull">
					<div className="e5line-0">
						{RenderPiePopover(mode === "detail1", this.props.downloadref, () =>
								DownloadNiListDetail("flashUsage", model, sofv, cla, this.props.toasterref),
							() => E5StoreCB.Ins().SetNiListSettings("none", "", undefined,
								undefined, undefined, undefined, undefined, undefined,
								undefined),
							RenderDetailsPie("flash", flusadetails, loading, PieClickDetail1, "flusa"))}
					</div>
				</div>
			</div>
			<div className="e5line-20">
				<div className="e5linefull">
					<div className="e5line-0">
						{RenderPiePopover(mode === "equip2", this.props.downloadref, () =>
								DownloadNiListEquip("flashCorruption", eqptype, cla, this.props.toasterref),
							() => E5StoreCB.Ins().SetNiListSettings("none", "", undefined,
								undefined, undefined, undefined, undefined, undefined,
								undefined),
							RenderEquipsPie("flash", flcorequips, loading, PieClickEquip2, "flcor"))}
					</div>
				</div>
				<div className="e5linefull">
					<div className="e5line-0">
						{RenderPiePopover(mode === "detail2", this.props.downloadref, () =>
								DownloadNiListDetail("flashCorruption", model, sofv, cla, this.props.toasterref),
							() => E5StoreCB.Ins().SetNiListSettings("none", "", undefined,
								undefined, undefined, undefined, undefined, undefined,
								undefined),
							RenderDetailsPie("flash", flcordetails, loading, PieClickDetail2, "flcor"))}
					</div>
				</div>
			</div>
			{RenderChart("flash", "gw", loading, this.props.percent, this.props.togglefunc, "flusa",'bar')}
			{E5MainConfig.GetExtEnabled() && RenderChart(
				"flash", "ext", loading, this.props.percent, this.props.togglefunc, "flusa",'bar')}
			{RenderChart("flash", "gw", loading, this.props.percent, this.props.togglefunc, "flcor",'bar')}
			{E5MainConfig.GetExtEnabled() && RenderChart(
				"flash", "ext", loading, this.props.percent, this.props.togglefunc, "flcor",'bar')}
		</div>;
	}
});
