import React, { useMemo, useState } from 'react'
import * as BP from "@blueprintjs/core";
import { observer } from "mobx-react";
//
import { E5StoreH } from "../../../store/E5StoreH";
import { E5PageTitle } from "../../../global/component/E5PageTitle";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5Store } from "../../../store/E5Store";
import { E5HFilters } from "../E5HFilters";
import E5HStationItem from "./E5HStationItem"
import './E5StationsPage.css'
import TextField from '@mui/material/TextField';
import { ReactComponent as Filter } from '../../../assets/table-filter.svg'

const E5HStationsPage = observer(() => {
    // force rerender when lang changes
    const curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
    const [searchKey, seySearchKey] = useState('')


    const { stationinfo, searchniautoinfo, searchniinfo, indicglobalinfo } = E5StoreH.Ins()
    const { langinfo, pageinfo } = E5Store.Ins()

    const filteredList = useMemo(() => {
        if (!stationinfo?.stations) return []
        return stationinfo.stations.filter((station) =>
            station.hostname.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()) ||
            station.macaddress.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
        )
    }, [stationinfo.stations, searchKey])
    return <div className="e5page e5wifih-metrics e5columnfull e5column-20 stations-page">
        <div className="e5column-5">
            <E5PageTitle titlekey="pagetitle-h-stations" />
        </div>
        <E5HFilters langinfo={langinfo} searchniinfo={searchniinfo} searchniautoinfo={searchniautoinfo} pageinfo={pageinfo} status={stationinfo.status}/>
        {/* <div className='stations-content'> */}
        <div className='stations-header'>
            <Filter className='filter-icon' />
            <TextField
                size="small"
                className='e5admin-users-filterbloc-search'
                id="outlined-basic"
                label="Station hostname / MAC addr"
                variant="outlined"
                value={searchKey}
                onChange={(event: any) => seySearchKey(event.target.value)} />
        </div>
        {/* <div className='stations-list'> */}
        {filteredList.map((station, index) => {
            const fingerprint = indicglobalinfo.fingerprintresult.find((fingerprint) => {
                return fingerprint.childId === station.macaddress
            });

            return <E5HStationItem
                station={station}
                key={index}
                equip={searchniinfo.networkid}
                fingerprint={fingerprint} />
        }

        )}
        {/* </div> */}
        {/* </div> */}
    </div>
})

export default E5HStationsPage;