import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { E5StoreH, E5StoreHSearchNi, E5StoreHStations, E5StoreHTopoSelected } from "../../store/E5StoreH";
import { E5EntHIncidWifi } from "../../entity/household/incid/E5EntHIncidWifi";
import { E5EntHEquip } from "../../entity/household/topo/E5EntHEquip";
import { E5BandEnum, E5NetElementType } from "../../entity/E5Enums";
import { E5Store, E5StoreLangInfo } from "../../store/E5Store";
import { E5Static } from "../../global/E5MainStatics";
import { E5UtilI18n } from "../../global/E5MainLang";
import { E5Text } from "../../util/E5Text";
//
import "./E5HStationList.css";

//E5
interface E5HStationListState {
	activefilter: any;
}

//E5
interface E5HStationListProps {
	selectedinfo: E5StoreHTopoSelected;
	stationinfo: E5StoreHStations;
	searchniinfo: E5StoreHSearchNi;
	langinfo: E5StoreLangInfo;
	minimal: boolean;
	nofilter?: boolean;
}

//E5
export const E5HStationList = observer(class E5HStationList extends React.PureComponent
	<E5HStationListProps, E5HStationListState> {

	// ---------------- MEMBERS ----------------

	//E5
	selected: boolean = false;
	bandshash: any = {};

	// ---------------- INIT ----------------

	//E5
	constructor(props: E5HStationListProps, state: E5HStationListState) {
		super(props, state);
		this.bandshash[E5BandEnum.eth] = this.bandshash[E5BandEnum.eth100] = this.bandshash[E5BandEnum.eth1000] =
			this.bandshash[E5BandEnum.freq2ghz] = this.bandshash[E5BandEnum.freq5ghz] =
			this.bandshash[E5BandEnum.freq6ghz] = true;
		this.state = { activefilter: { ...this.bandshash } };
	}

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line

		return this.props.minimal ? this.RenderMinimal() : this.RenderNormal();
	}

	//E5
	RenderMinimal(): JSX.Element {
		if (this.selected && this.props.stationinfo.stations.length === 0) this.selected = false;

		let stajsx: JSX.Element[] = [];
		for (let sta of this.props.stationinfo.stations) {
			if (!this.selected && E5StoreH.Ins().equipinfo.equips.length < 2) {
				this.selected = true;
				E5StoreH.Ins().ChangeSelectedElem(E5NetElementType.device, new E5EntHEquip(), sta);
			}

			let img: string = "/img/nodes_and_devices/" + sta.devicetypecateg + "-black.png",
				scoreimg: string = "/img/scores/score-" + E5HStationList.GetScorePart(sta.health) + ".png",
				selectedclass: string = this.props.selectedinfo.id === sta.macaddress ? "selected" : "";

			stajsx.push(<div onClick={this.Selected}
				id={sta.macaddress} key={sta.macaddress} className={"clickable " + selectedclass}>
				<div className="e5line-5">
					<img className="img" src={img} alt="" />
					<img className="scoreimg" src={scoreimg} alt="" />
					<div className="minimaltext">
						<div className="value">{sta.macaddress}</div>
						<div className="value">{sta.hostname}</div>
					</div>
				</div>
			</div>);
		}

		return <div className="e5compo e5wifih-health-devices minimal">
			<div className="e5column-5">{stajsx}</div>
		</div>;
	}

	//E5
	RenderNormal(): JSX.Element {
		let { _ } = E5UtilI18n, stajsx: JSX.Element[] = [];
		for (let sta of this.props.stationinfo.stations) {
			if (this.state.activefilter[sta.band] !== true) continue;

			let img: string = "/img/nodes_and_devices/" + sta.devicetypecateg + "-black.png",
				scoreimg: string = "/img/scores/score-" + E5HStationList.GetScorePart(sta.health) + ".png",
				selectedclass: string = this.props.selectedinfo.id === sta.macaddress ? "selected" : "",
				band: string = this.bandshash[sta.band] !== undefined ? sta.band : E5EntHIncidWifi.bandunknown,
				iseth: boolean = sta.wifistdavailable.length === 0 && sta.wifistdused.length === 0,
				eth: JSX.Element = iseth ? <BP.Checkbox checked /> : <div />,
				linktypestr: string = sta.linktype.toUpperCase() === "wifi".toUpperCase() ? "Wi-Fi" : sta.linktype;
			let ethScore;
			if (sta.linktype === 'Ethernet' && sta.health === 0) {
				scoreimg = "/img/scores/score-eth.png";
				ethScore = 'ETH'
			}
			stajsx.push(<div onClick={this.Selected}
				id={sta.macaddress} key={sta.macaddress} className={"clickable " + selectedclass}>
				<div className="e5line-5">
					<div className="e5column-10 iconhealth">
						<img className="img" src={img} alt="" />
						<div className="scorediv">
							<img className="scoreimg" src={scoreimg} alt="" />
							<div className="scorevalue">{ethScore ? ethScore : `${E5Text.ScoreToPercent(sta.health)}%`}</div>
						</div>
					</div>
					<div className="texts e5linefull">
						<div className="label">{_("wifih-health-desc-macaddress")}</div>
						<div className="label">{_("wifih-health-desc-hostname")}</div>
						<div className="label">{_("wifih-health-desc-linktype")}</div>
						<div className="label">{_("wifih-health-desc-band")}</div>
						<div className="label">{_("wifih-health-desc-dev-lastseen")}</div>
						<div className="value">{sta.macaddress}</div>
						<div className="value">{sta.hostname}</div>
						<div className="value">{linktypestr}</div>
						<div className="value">{_(band)}</div>
						{<div className="value">
							{sta.lastseen?.format("HH:mm:ss") ?? "N/A"}
							{sta.lastseen !== null
								&& E5StoreH.Ins().curdateinfo.curdate === E5Store.Ins().todayinfo.today &&
								<span>&nbsp;<i>({sta.lastseen.fromNow()})</i></span>}
						</div>}
						<BP.Divider /><BP.Divider /><BP.Divider /><BP.Divider /><BP.Divider />
						<div className="label">{_("wifih-health-desc-curbandwidth")}</div>
						<div className="label">{_("wifih-health-desc-chan")}</div>
						<div className="label">{_("wifih-health-desc-maxbitrate")}</div>
						{!iseth && <>
							<div className="label">{_("wifih-health-desc-wifistdavailable")}</div>
							<div className="label">{_("wifih-health-desc-wifistdused")}</div>
						</>}
						{iseth && <>
							<div className="label">{_("wifih-health-desc-ethernet")}</div>
							<div />
						</>}
						<div className="value">{sta.curbandwidth}</div>
						<div className="value">{sta.channelinuse}</div>
						<div className="value">{sta.maxbitrate} {iseth ? "Mb/s" : "kb/s"}</div>
						{!iseth && <>
							<div className="value">{sta.wifistdavailable.join(", ")}</div>
							<div className="value">{sta.wifistdused.join(", ")}</div>
						</>}
						{iseth && <>
							<div className="value">{eth}</div>
							<div />
						</>}
					</div>
				</div>
			</div>);
		}

		let showfilters: boolean = this.props.nofilter !== true && this.props.searchniinfo.networkid !== "",
			filtersjsx: JSX.Element[] = [];
		if (showfilters) {
			filtersjsx = [
				<BP.Button key="button-all" text={_("all")} minimal small
					onClick={() => this.ResetFilter(true)} />,
				<BP.Button key="button-none" text={_("none")} minimal small
					onClick={() => this.ResetFilter(false)} />
			];
			let band: string;
			for (band of Object.values(E5BandEnum)) {
				if (band === "") continue;
				filtersjsx.push(<BP.Checkbox key={"f" + band} label={_(band)} inline value={band}
					checked={this.state.activefilter[band] === true}
					onChange={this.ChangeFilterBand} />);
			}
		}

		return <div className="e5compo e5wifih-health-devices">
			{showfilters && <div className="e5line-5 filter">{filtersjsx}</div>}
			<div className="e5column-5">{stajsx}</div>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	Selected = (event: React.MouseEvent<HTMLElement>) =>
		E5StoreH.Ins().ChangeSelectedElem(E5NetElementType.device, new E5EntHEquip(),
			this.props.stationinfo.stations.filter(({ macaddress }) => macaddress === event.currentTarget.id)[0]);

	//E5
	ChangeFilterBand = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value: string = event.currentTarget.value, activefilter: any = { ...this.state.activefilter };
		activefilter[value] = event.currentTarget.checked;
		this.setState({ activefilter });
	};

	//E5
	ResetFilter = (all: boolean): void => {
		let filt: any = {}
		filt[E5BandEnum.eth] = filt[E5BandEnum.eth100] = filt[E5BandEnum.eth1000] = filt[E5BandEnum.freq2ghz] =
			filt[E5BandEnum.freq5ghz] = filt[E5BandEnum.freq6ghz] = all;
		this.setState({ activefilter: filt });
	};

	// ---------------- UTILS ----------------

	//E5
	static GetScoreNewColorScheme(health: number): number {
		const score = E5Static.newtresholds.findIndex((t) => Math.round(health * 100) < t);

		if (score === -1) {
			return 9;
		}

		return score === 0 ? score : score - 1;
	}

	//E5
	static GetScorePart(health: number): number | null {
		if (health === null) return null;

		let idxi: number, scoreperc: number = Math.round(health * 100), scorepart: number = 0;
		for (idxi = 1; idxi < E5Static.gaugethresholds.length; idxi++) {
			if (scoreperc >= E5Static.gaugethresholds[idxi - 1] &&
				scoreperc <= E5Static.gaugethresholds[idxi]) {
				scorepart = idxi - 1;
				break;
			}
		}
		return scorepart;
	}
});
