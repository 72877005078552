import React from "react";
import {Moment} from "moment";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5Store, E5StoreLangInfo} from "../../../store/E5Store";
import {E5HIncidentButton} from "../incident/E5HIncidentButton";
import {E5RequestMeta} from "../../../request/E5RequestMeta";
import {E5HIncidentHelp} from "../incident/E5HIncidentHelp";
import {E5MainConfig} from "../../../global/E5MainConfig";
import {E5RequestH} from "../../../request/E5RequestH";
import {E5UtilI18n} from "../../../global/E5MainLang";
import {E5Text} from "../../../util/E5Text";
import {
	E5StoreHIncidents, E5StoreHIncidentSelIdx, E5StoreHSearchNi, E5StoreHStations, E5StoreHEquips, E5StoreH,
	E5EntHIncidAny
} from "../../../store/E5StoreH";
//
import "./E5HTop5Incidents.css";

//E5
interface E5HTop5IncidentsState {}

//E5
interface E5HTop5IncidentsProps {
	langinfo: E5StoreLangInfo;
	incidentinfo: E5StoreHIncidents;
	searchniinfo: E5StoreHSearchNi;
	selincidentinfo: E5StoreHIncidentSelIdx;
	equipinfo: E5StoreHEquips;
	stationinfo: E5StoreHStations;
}

//E5
export const E5HTop5Incidents = observer(class E5HTop5Incidents extends React.PureComponent
	<E5HTop5IncidentsProps, E5HTop5IncidentsState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line

		let incidentsjsx: JSX.Element[] = [], {loading, incidentmap, messagemap} = this.props.incidentinfo,
			datemom: Moment = E5RequestH.DateOrYesterday(E5StoreH.Ins().curdateinfo.curdate),
			datestr: string = datemom.format("YYYY-MM-DD"), cnt: number = 0,
			datelocalstr: string = datemom.format(E5UtilI18n._("dateformat")),
			incidents: E5EntHIncidAny[]|undefined = incidentmap.get(datestr);

		if (incidents !== undefined) for (let idx = 0; idx < incidents.length && cnt < 5; idx++) {
			let {id} = incidents[idx];
			if (id !== "" && (!id.startsWith(E5RequestMeta.h_inc_wifi_nb) || E5MainConfig.GetWifiEnabled())
				&& (!id.startsWith(E5RequestMeta.h_inc_wan_nb) || E5MainConfig.GetWanEnabled())
				&& (!id.startsWith(E5RequestMeta.h_inc_sys_nb) || E5MainConfig.GetSystemEnabled())
				&& (!id.startsWith("I0301") || E5MainConfig.GetSystemRebootEnabled())
				&& (!id.startsWith("I0302") || E5MainConfig.GetSystemCpuEnabled())
				&& (!id.startsWith("I0303") || E5MainConfig.GetSystemMemoryEnabled())
				&& (!id.startsWith("I0304") || E5MainConfig.GetSystemProcessEnabled())
				&& (!id.startsWith("I0305") || E5MainConfig.GetSystemFlashEnabled())
				&& (!id.startsWith("I0306") || E5MainConfig.GetSystemTemperatureEnabled())) {
				cnt++;
				incidentsjsx.push(<E5HIncidentButton
					indexinall={idx} incident={incidents[idx]} langinfo={E5Store.Ins().langinfo}
					stationmap={this.props.stationinfo.stationmap} key={"e5wifih-health-incidents-incident" + idx}
					equipmap={this.props.equipinfo.equipmap} selincidentinfo={E5StoreH.Ins().selincidentinfo}
					incidentinfo={E5StoreH.Ins().incidentinfo}/>);
			}
		}

		let incidentfor: string = incidents !== undefined && incidents.length > 0 ?
			E5Text.Substitute(E5UtilI18n._("wifih-incident-date"),
				[datelocalstr]) : messagemap.get(datestr) ?? "";

		let {status, networkid} = this.props.searchniinfo,
			canload: boolean = !status.loading && !this.props.incidentinfo.loading && networkid !== "";

		return <div className="e5compo e5wifih-health-incidents">
			<div className="e5compotitle">{E5UtilI18n._("wifih-health-incidents")}
				{loading && <BP.Spinner className="e5spinwait" size={15}/>}
			</div>
			<div className="e5line-10">
				<BP.Button disabled={!canload} onClick={this.LoadIncidents} icon="refresh"
						   text={E5UtilI18n._("wifih-incident-day-load-button") + datelocalstr}/>
				<E5HIncidentHelp langinfo={E5Store.Ins().langinfo}/>
				<div className="e5linefull"/>
				<div className="incidentdate">{incidentfor}</div>
			</div>
			<div style={{height: "5px"}}/>
			{!loading && <>
				<div className="e5column-5">{incidentsjsx}</div>
			</>}
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	LoadIncidents = (): void =>
		E5RequestH.FetchIncidents(this.props.searchniinfo.networkid,
			0, E5StoreH.Ins().curdateinfo.curdate, true, () =>
				E5StoreH.Ins().SetIncidents(null, false, null, null, null));
});
