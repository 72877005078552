import React from "react";
import { observer } from "mobx-react";
//
import { E5TextIndic, E5TextIndicUnitEnum } from "../../../global/component/E5TextIndic";
import { E5NetElementType } from "../../../entity/E5Enums";
import { E5StoreLangInfo } from "../../../store/E5Store";
import { E5UtilI18n } from "../../../global/E5MainLang";
//
import "./E5HMetricIndic.css";

//E5
interface E5HMetricIndicState { }

//E5
interface E5HMetricIndicProps {
	langinfo: E5StoreLangInfo;
	elemtype: E5NetElementType;
	elemname: string;
	avgeth?: number;
	avgwifi?: number;
	health?: number;
	rebootcnt?: number;
	flashusage?: number;
	mode?: "wifi" | "system" | "process" | 'proccess-handling';
	children?: any;
}

//E5
export const E5HMetricIndic = observer(class E5HMetricIndic extends React.PureComponent
	<E5HMetricIndicProps, E5HMetricIndicState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = this.props.langinfo.curlang; //eslint-disable-line
		//
		let typelabel: string = "";
		if (this.props.elemtype !== E5NetElementType.none)
			typelabel = E5UtilI18n._("netelementtype-" + this.props.elemtype);
		//
		return <div className="e5compo e5wifih-metrics-indic e5line-0">
			{this.props.mode !== 'wifi' && this.props.mode !== 'system' && this.props.mode !== 'process' && <E5TextIndic
				valuestr={this.props.elemname}
				label={typelabel}
				imageurl="/img/v3/nodes_and_devices/desktop-black.svg"
				small={true}
				textAlign="left"
				tooltip={E5UtilI18n._("wifih-indic-elemname-tip")} />}
			<E5TextIndic
				value={this.props.health}
				imageurl="/img/v3/metrics/health.svg"
				label={E5UtilI18n._("cb-health")}
				textAlign="left"
				unittype={E5TextIndicUnitEnum.score}
				small={true}
				tooltip={E5UtilI18n._("wifih-metrics-globalscore")} />
			{this.props.mode === "system" && <>
				<E5TextIndic
					value={this.props.rebootcnt}
					label={E5UtilI18n._("wifih-indic-rebootcnt")}
					imageurl="/img/v3/metrics/reboot.svg"
					textAlign="left"
					small={true}
					unittype={E5TextIndicUnitEnum.count}
					tooltip={E5UtilI18n._("wifih-indic-rebootcnt-tip")} />
				<E5TextIndic
					value={this.props.flashusage}
					label={E5UtilI18n._("wifih-indic-flashusage")}
					imageurl="/img/v3/metrics/usage.svg"
					textAlign="left"
					small={true}
					unittype={E5TextIndicUnitEnum.score} />
			</>}

			{this.props.children && <div className={'e5wifih-metrics-indic-children'}>{this.props.children}</div>}
		</div>;
	}
});
