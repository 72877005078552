import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/system';
import { ReactNode } from 'react';
import './SelectStyled.css';
import { FormControl, InputLabel } from '@mui/material';

const StyledSelect = styled(Select)({
    '&': {
        fontFamily: 'Montserrat',
    },
    '&.MuiOutlinedInput-root': {
        padding: '8.5px 14px',
        fieldset: {
            borderColor: '#0080FF',
            borderRadius: '10px',
        },
        '&:hover fieldset': {
            borderColor: '#0080FF',
        },
        '& fieldset': {
            borderColor: '#0080FF',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#0080FF',
        },
    },
    '& .MuiOutlinedInput-input': {
        padding: '0 !important',
        fontFamily: 'Montserrat',
        fontSize: '14px'
    },
});

interface SelectStyledProps {
    data: { label: string | JSX.Element, value: any, disable?: boolean }[];
    onChange: (value: any) => void;
    value: string | number | number[] | string[] | undefined;
    defaultValue?: string | number | number[];
    className?: string;
    label?: string;
    error?: boolean;
    required?: boolean;
    disabled?: boolean;
    multiple?: boolean;
}

const SelectStyled: React.FC<SelectStyledProps> = ({ data, onChange, value, defaultValue, className, label, error, required, disabled, options, multiple }) => {
    const generateMenuItems = () => {
        return data.map((item) => (
            <MenuItem className='select-styled-item' key={item.value} value={item.value} disabled={item.disable ?? false}>
                {item.label}
            </MenuItem>
        ));
    };

    const handleChange = (event: SelectChangeEvent<unknown>, child: ReactNode) => {
        const {
            target: { value },
        } = event;
        onChange(value as string);
    };

    return (
        <FormControl required={required ?? false} error={error ?? false}>
            <InputLabel id="select-label">{label}</InputLabel>
            <StyledSelect
                labelId="select-label"
                className={className}
                label={label}
                value={value}
                defaultValue={defaultValue}
                onChange={handleChange}
                displayEmpty={false}
                required={required ?? false}
                disabled={disabled ?? false}
                multiple={multiple ?? false}
            >
                {generateMenuItems()}
            </StyledSelect>
        </FormControl>
    );
};

export default SelectStyled;