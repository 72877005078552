import React from "react";
import { observer } from "mobx-react";
import moment, { Moment } from "moment";
import * as BP from "@blueprintjs/core";
//
import { E5XYNumData, E5XYSource } from "../../../global/plot/E5XYChart"; //eslint-disable-line
import { E5RequestStatus, E5StoreAngularGaugeInfo } from "../../../request/E5ServiceCommon";
import { E5EntWifiChanTime } from "../../../entity/E5EntWifiChanTime";
import { E5PageTitle } from "../../../global/component/E5PageTitle";
import { E5MainConfig } from "../../../global/E5MainConfig";
import { E5RequestH } from "../../../request/E5RequestH";
import { E5HDashboardIndic } from "./E5HDashboardIndic";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5Store } from "../../../store/E5Store";
import { E5Text } from "../../../util/E5Text";
import { E5HFilters } from "../E5HFilters";
import { E5HTopology } from '../connectivity/E5HTopology'
import {
	E5StoreHStations, E5StoreHIndicGlobal, E5StoreHEquips, E5StoreHIndicWifi, E5HHealthScores, E5StoreH, E5EntHIncidAny
} from "../../../store/E5StoreH";
//
import "./E5HDashboard.css";
import { E5HStationList } from "../E5HStationList";
import { E5RequestMeta } from "../../../request/E5RequestMeta";
import { Popover, PopoverInteractionKind, Position, Spinner, Tab } from "@blueprintjs/core";
import { E5HActivity } from "./E5HActivity";
import E5NewAngularGauge from "../../../global/plot/E5NewAngularGauge";
import { incidentsChartOptions } from "../../../util/E5DashboardHealthUtils";
import ReactECharts from 'echarts-for-react';

//E5
interface E5HDashboardState {
	householdIdFromQuery: string;
	pageStatus: E5RequestStatus;
	currentIdPage: string;
}

//E5
interface E5HDashboardProps {
	stationinfo: E5StoreHStations;
	equipinfo: E5StoreHEquips;
	indicglobalinfo: E5StoreHIndicGlobal;
	indicwifiinfo: E5StoreHIndicWifi;
	location: any;
	history: any;

}

//E5
export const E5HDashboard = observer(class E5HDashboard extends React.PureComponent
	<E5HDashboardProps, E5HDashboardState> {

	private topologyRef = React.createRef<any>();
	
	constructor(props: E5HDashboardProps, state: E5HDashboardState) {
		super(props, state);
		this.state = { householdIdFromQuery: "", pageStatus: E5StoreH.Ins().connectivityinfo.status, currentIdPage: "h-dashboard-topology" };
	}
	// ---------------- RENDER ----------------
	componentWillMount() {
		const query = new URLSearchParams(this.props.location.search);
		const householdId = query.get('householdId')
		if (householdId) {
			this.setState({ householdIdFromQuery: householdId })
		}
	}
	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
		const anomaliesNames = E5RequestMeta.Ins().h_anomalyNames;
		const incidentsNames = E5RequestMeta.Ins().h_incidentsNames;

		this.getStatusPage(this.state.currentIdPage);

		let { langinfo } = E5Store.Ins();
		let { searchniinfo, selectedinfo, equipinfo, stationinfo, incidentinfo, anomsumminfo, connectivityinfo } = E5StoreH.Ins();
		let { _ } = E5UtilI18n, { loading, healths, wifihealth, wanhealth, syshealth } = this.props.indicglobalinfo;

		let datemom: Moment = E5RequestH.DateOrYesterday(E5StoreH.Ins().curdateinfo.curdate),
			datestring: string = datemom.format("YYYY-MM-DD");
		let incidents = incidentinfo.incidentmap.get(datestring);

		const incidentsValues: [string, number, string, string][] = this.FilteredIncidentList(incidents)
			.map(({ id, criticality }) => {
				const incident = incidentsNames?.find((inc) => inc.id === id);
				return [incident?.name, criticality, id, '#e53935'];
			}) ?? [];
		const incidentesPopOverContent: [string, string][] = incidentsValues.map((inc) => [inc[2], inc[0]]);

		const anomaliesValues: [string, number, string][] = anomsumminfo.anoms
			.filter(({id, criticality}) => criticality > 0)
			.map(({ id, criticality }) => {
				const anomaly = anomaliesNames.find((item) => item?.id === id);
				return [anomaly?.name, criticality, id];
			}) ?? [];
		const anomaliesPopOverContent: [string, string][] = anomaliesValues.map((anom) => [anom[2], anom[0]]);

		let gaugeinfo: E5StoreAngularGaugeInfo = { loading, title: _("wifih-health-scores") },
			wifienabled: boolean = E5MainConfig.GetWifiEnabled(), wanenabled: boolean = E5MainConfig.GetWanEnabled(),
			systemenabled: boolean = E5MainConfig.GetSystemEnabled();

		gaugeinfo.value = wifienabled ? wifihealth : wanenabled ? wanhealth : systemenabled ? syshealth : undefined;
		gaugeinfo.label = wifienabled ? "Wi-Fi" : wanenabled ? "WAN" : systemenabled ? _("system") : undefined;

		if (wifienabled) {
			gaugeinfo.value2 = wanenabled ? wanhealth : systemenabled ? syshealth : undefined;
			gaugeinfo.label2 = wanenabled ? "WAN" : systemenabled ? _("system") : undefined;
		} else if (wanenabled) {
			gaugeinfo.value2 = systemenabled ? syshealth : undefined;
			gaugeinfo.label2 = systemenabled ? _("system") : undefined;
		}

		if (wifienabled && wanenabled) {
			gaugeinfo.value3 = systemenabled ? syshealth : undefined;
			gaugeinfo.label3 = systemenabled ? _("system") : undefined;
		}

		let idx: number, weekscoresjsx: JSX.Element[] = healths.length === 0 ? [] : [
			<div className="dayscores" key="dayscores-1">
				<div className="scorediv" />
				{wifienabled && <div className="scorediv text"><b>Wi-Fi</b></div>}
				{wanenabled && <div className="scorediv text"><b>WAN</b></div>}
				{systemenabled && <div className="scorediv text"><b>{_("system")}</b></div>}
			</div>
		];

		let datestr: string;
		for (idx = 0; idx < healths.length; idx++) {
			let scores: E5HHealthScores = healths[idx], datemom: Moment = moment(scores.date);
			datestr = scores.date === "" ? "N/A" : datemom.format("MM/DD");
			let iscurdate: boolean = E5StoreH.Ins().curdateinfo.curdate === datemom.format("YYYY-MM-DD");
			weekscoresjsx.push(<div className="dayscores" key={"dayscores" + idx}>
				<div className="scorediv text">{iscurdate ? <b>{datestr}</b> : datestr}</div>
				{wifienabled && this.RenderScore(scores.wifi)}{wanenabled && this.RenderScore(scores.wan)}
				{systemenabled && this.RenderScore(scores.sys)}
			</div>);
		}

		return <div className="e5page e5wifih-dashboard e5columnfull e5column-20">
			<div className="e5column-5">
				<E5PageTitle titlekey="pagetitle-h-dashboard" />
			</div>
			<div className='dashboard-filters'>
				<E5HFilters langinfo={langinfo} searchniinfo={searchniinfo} pageinfo={E5Store.Ins().pageinfo} status={this.state.pageStatus}
					searchniautoinfo={E5StoreH.Ins().searchniautoinfo} householdIdFromQuery={this.state.householdIdFromQuery} dashboardHPage />
				<E5HDashboardIndic langinfo={langinfo} indicglobalinfo={E5StoreH.Ins().indicglobalinfo} />
			</div>
			<div className="e5wifih-dashboard-content e5columnfull e5column-0">
				<div className="e5wifih-dashboard-components e5columnfull e5column-0">
					<BP.Tabs className="e5columnfull" onChange={this.OnChangeTab}>
						{loading && <BP.Spinner className="topowait" size={15} />}
						<BP.Tab id="h-dashboard-topology"
							title={E5UtilI18n._("wifih-topology-tab-topology")} panel={<E5HTopology ref={this.topologyRef}  langinfo={E5Store.Ins().langinfo} selectedinfo={selectedinfo}
								stationinfo={stationinfo} equipinfo={equipinfo}
								connectivityinfo={connectivityinfo} />} />

						<BP.Tab className="e5column-0" id="h-dashboard-health" title={_("h-dashboard-health")}
							panel={<div className="dashboard-tab e5column-20">
								<div className='dashboard-health-container'>
									<E5NewAngularGauge label={gaugeinfo.label + ' health score'} value={gaugeinfo.value} />
									<E5NewAngularGauge label={gaugeinfo.label2 + ' health score'} value={gaugeinfo.value2} />
									<E5NewAngularGauge label={gaugeinfo.label3 + ' health score'} value={gaugeinfo.value3} displayZero/>
								</div>
								<div className="e5compo e5column-5">
									<div className="e5compotitle">{_("wifih-health-week-scores")}
										{loading && <BP.Spinner className="e5spinwait" size={15} />}
									</div>
									<div className="weekscores">{weekscoresjsx}</div>
								</div>
								<div className='bar-charts-container'>
									{this.RenderTopChart(E5UtilI18n._("wifih-health-incidents"), incidentinfo.loading, incidentsValues, incidentesPopOverContent, true)}
									{this.RenderTopChart(E5UtilI18n._("h-dashboard-anomaly-chart"), anomsumminfo.status.loading, anomaliesValues, anomaliesPopOverContent)}
								</div>
							</div>} />

						<Tab id="h-dashboard-activity" title={E5UtilI18n._("h-dashboard-activity")} panel={
								<E5HActivity activityDeviceWeek={E5StoreH.Ins().activityDeviceWeek} activityDeviceDay={E5StoreH.Ins().activityDeviceDay}/>
							} />
					</BP.Tabs>
				</div>
			</div>
		</div>;
	}

	//E5
	RenderTopChart = (title: string, loading: boolean, data: [string, number, string, string][] | [string, number, string, string][], popoverContent: Array<[string, string]>, isIncidents: boolean = false) => {
		if (loading) {
			return (
			<div className="e5compo e5column-5">
				<div className="e5compotitle">{title}</div>
				<div className="e5line-20 e5hhealth-top-chart-content">
					<Spinner className="e5spinwait" size={30} />
				</div>
			</div>
			);
		} else if (!data.length) {
			return (
				<div className="e5compo e5column-5">
					<div className="e5compotitle">{title}</div>
					<div className="e5line-20 e5hhealth-top-chart-content">
						<span className="no-data">{E5UtilI18n._(loading ? "wificb-dashboard-notfound" : "no-data-collected")}</span>
					</div>
				</div>
			);
		} else {
			return (
				<div className="e5compo e5column-5">
					<div className="top-chart-header">
						<span className="e5texttitle">
							{title}
						</span>

						<Popover 
							content={this.TopChartPopoverContent(popoverContent)}
							interactionKind={PopoverInteractionKind.HOVER} 
							position={Position.BOTTOM}>
							<img className="e5iconinfo e5cursorpointer" src="/img/info-black.svg" alt={title} />
						</Popover>
					</div>
					<ReactECharts option={incidentsChartOptions(data, isIncidents)} />
				</div>
			);
		}
	}

	//E5
	TopChartPopoverContent = (data: Array<[string, string]>) => {
		return (
			<div className="popover-info-list">
				{ data.map((item, index) => <p className="popover-info-item" key={index}>{item[0]}: {item[1]}</p>) }
			</div>
		);
	}

	//E5
	RenderScore = (score: number | null): JSX.Element => {
		let jsx: JSX.Element;
		if (score === null) jsx = <div className="scorediv text">N/A</div>;
		else {
			let img: string = `/img/scores/new-scores/score-${E5HStationList.GetScoreNewColorScheme(score)}.svg`;
			jsx = <div className="scorediv e5line-5">
				<img className="scoreimg" src={img} alt="" />
				<div>{E5Text.ScoreToPercent(score) + "%"}</div>
			</div>;
		}
		return jsx;
	};

	// ---------------- EVENTS ----------------

	RefreshAnom = (): void => {
		if (E5StoreH.Ins().searchniinfo.networkid !== "")
			E5RequestH.Ins().FetchAnomSummary(E5StoreH.Ins().searchniinfo.networkid, undefined);
	};

	// ---------------- UTILS ----------------

	//E5
	FilteredIncidentList = (incidents: E5EntHIncidAny[] | undefined): { id: string; criticality: number; }[] => {
		if (!incidents) return [];

		const incidentsIds = incidents.map(({ id }) => id);
		const incidentsIdsUnique = [...new Set(incidentsIds)];
		
		const mappedIncidents: {
			id: string,
			criticality: number
		}[] = [];
		
		incidentsIdsUnique.forEach((id) => {
			let calculatedIncidents;
			const criticality = incidents.reduce((acc, inc) => {
				if (inc.id === id) {
					acc += inc.criticality;
				}
				return acc;
			}, 0);
			calculatedIncidents = {
				id,
				criticality
			};
			mappedIncidents.push(calculatedIncidents);
		});

		mappedIncidents.sort((a, b) => b.criticality - a.criticality);
		return mappedIncidents?.slice(0, 5);
	};

	OnChangeTab = (newTabId: BP.TabId, prevTabId: BP.TabId | undefined): void => {
		this.getStatusPage(newTabId.toString());
	};

	getStatusPage = (currentIdPage: string): void => {
		switch (currentIdPage) {
			case "h-dashboard-topology":
				this.topologyRef?.current?.RepositionTopology();
				this.setState({pageStatus: E5StoreH.Ins().connectivityinfo.status, currentIdPage});
				break;
			case "h-dashboard-health":
				if (E5StoreH.Ins().incidentinfo.status.message !== '') {
					this.setState({pageStatus: E5StoreH.Ins().incidentinfo.status, currentIdPage});
				} else {
					this.setState({pageStatus: E5StoreH.Ins().anomsumminfo.status, currentIdPage});
				}
				break;
			case "h-dashboard-activity":
				if (E5StoreH.Ins().activityDeviceWeek.status.message !== '') {
					this.setState({pageStatus: E5StoreH.Ins().activityDeviceWeek.status, currentIdPage});
				} else {
					this.setState({pageStatus: E5StoreH.Ins().activityDeviceDay.status, currentIdPage});
				}
				break;
		}
	};

	//E5
	static BuildChanConnTimeSource: (times: E5EntWifiChanTime[], src: E5XYSource, onlyhours?: boolean) => void = (times:
		E5EntWifiChanTime[], src: E5XYSource, onlyhours?: boolean): void => {
		let { _ } = E5UtilI18n, datamap: Map<string, E5XYNumData> = new Map(), ytickvals: string[] = [];
		if (onlyhours === true) {
			let hovertemplate: string = "<extra>%{fullData.name}</extra>";
			for (let { band, chantime } of times) {
				let bandlabel: string = E5Text.Substitute(_("wifih-health-desc-bandchans"), [band]);
				for (let [chan, time] of chantime) {
					let timestr: string = moment(time * 1000).utc().format(), data: E5XYNumData = {
						xaxisdata: [bandlabel], yaxisdata: [timestr], datalabel: _("wifih-health-desc-band") +
							" " + band + " " + _("wifih-health-desc-chan") + " " + chan,
					};
					ytickvals.push(timestr);
					datamap.set(chan + ";" + time, data);
					src.numdatas?.push(data);
				}
			}
			if (ytickvals.length >= 1 && src.options !== undefined) {
				ytickvals.sort();
				src.options.yticktext = ["0h 0m 0s"];
				src.options.ytickvals = [moment(0).utc().format(), ytickvals[ytickvals.length - 1]];
				for (let [key, data] of datamap) {
					let time: number = parseInt(key.split(";")[1]);
					if (moment(time * 1000).utc().format() === ytickvals[ytickvals.length - 1]) {
						src.options.yticktext.push(E5Text.Seconds2H_str(time));
						data.hovertemplate = "%{y}" + hovertemplate;
					} else data.hovertemplate = E5Text.Seconds2H_str(time) + hovertemplate;
				}
			}
		} else {
			let hovertemplate: string = "%{y}<extra>%{fullData.name}</extra>";
			for (let idx = 0; idx < times.length; idx++) {
				let bandlabel: string = E5Text.Substitute(_("wifih-health-desc-bandchans"), [times[idx].band]);
				for (let [chan, time] of times[idx].chantime) {
					let timestr: string = moment(time * 1000).utc().format(), data: E5XYNumData = {
						datalabel: _("wifih-health-desc-band") + " " + times[idx].band + " " +
							_("wifih-health-desc-chan") + " " + chan, xaxisdata: [bandlabel], yaxisdata: [timestr]
					};
					ytickvals.push(timestr);
					datamap.set(String(time), data);
					src.numdatas?.push(data);
				}
			}
			if (ytickvals.length >= 1 && src.options !== undefined) {
				ytickvals.sort();
				src.options.yticktext = ["0h 0m 0s"];
				src.options.ytickvals = [moment(0).utc().format(), ytickvals[ytickvals.length - 1]];
				for (let [timestr, data] of datamap) {
					let time: number = parseInt(timestr), dur: moment.Duration = moment.duration(time * 1000);
					if (moment(time * 1000).utc().format() === ytickvals[ytickvals.length - 1]) {
						src.options.yticktext.push((dur.days() === 0 ? "" : dur.days() + _("dayshort") + " ")
							+ dur.hours() + "h " + dur.minutes() + "m " + dur.seconds() + "s");
						data.hovertemplate = hovertemplate;
					} else data.hovertemplate = (dur.days() === 0 ? "" : dur.days() + _("dayshort") + " ") +
						hovertemplate;
				}
			}
		}
	};
});
