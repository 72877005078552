import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { E5EntCBSysOverMet, E5SysOverMetInc } from "../../../../entity/customer_base/E5EntCBSysOverMet";
import { E5XYChart, E5XYNumData, E5XYSource } from "../../../../global/plot/E5XYChart";
import { E5StoreCBSys, E5StoreCBSysOverview } from "../../../../store/E5StoreCBSys";
import { E5PieChart, E5PieChartClickCB } from "../../../../global/plot/E5PieChart";
import { E5AngularGauge } from "../../../../global/plot/E5AngularGauge";
import { E5RequestStatus } from "../../../../request/E5ServiceCommon";
import { E5RequestCBSys } from "../../../../request/E5RequestCBSys";
// import {E5RadarChart} from "../../../../global/plot/E5RadarChart";
import { E5RequestMeta } from "../../../../request/E5RequestMeta";
import { E5MainConfig } from "../../../../global/E5MainConfig";
import { E5Static } from "../../../../global/E5MainStatics";
import { E5UtilI18n } from "../../../../global/E5MainLang";
import { E5StoreCB } from "../../../../store/E5StoreCB";
import { E5Store } from "../../../../store/E5Store";
import { E5CBDashboard } from "../E5CBDashboard";
import { E5Text } from "../../../../util/E5Text";
//
import "./E5CBSysOverview.css";

//E5
interface E5CBSysOverviewState { }

//E5
interface E5CBSysOverviewProps {
	toasterref: React.RefObject<BP.Toaster>;
	downloadref: React.RefObject<BP.Button>;
	percent: boolean;
	togglefunc: () => void;
}

//E5
export const E5CBSysOverview = observer(class E5CBSysOverview extends React.PureComponent
	<E5CBSysOverviewProps, E5CBSysOverviewState> {

	// ---------------- MEMBERS ----------------

	static clarankmap: Map<string, string> = new Map();

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
		//
		let { _ } = E5UtilI18n, { status, health } = E5StoreCBSys.Ins().overview,
			{ loading } = status, { nilist, nilistsettings } = E5StoreCB.Ins(),
			{ mode, downloadstr, date, incid, cla } = nilistsettings;
		//
		let pieincinfo: any = E5CBSysOverview.GetPieIncidentInfo(E5StoreCBSys.Ins().overview, E5RequestMeta.Ins().h_incidentcateglen);
		//
		let radarvalues: number[] = E5CBSysOverview.GetRadarValue(E5StoreCBSys.Ins().overview);
		// let avghealthcolor: string = E5CBSysOverview.GetAvgHealthColor(radarvalues);
		//
		return <div className="e5cb-sys-overview e5column-20">
			<div className="e5line-20">
				<div className="e5linefull">
					<E5AngularGauge
						gaugeinfo={{ value: health, label: _("system"), title: E5UtilI18n._("cb-sys-overview-health-score"), loading }} isNewComponent chartOption={{ type: 'gauge' }} />
				</div>
				<div className="e5linefull2">
					<div className="e5line-0">
						<BP.Popover className="e5linefull e5line-0" targetClassName="e5linefull"
							isOpen={mode === "health"} content={<div className="e5cb-dashboard-downloaddiv">
								<div className="title">{downloadstr}</div>
								{nilist.status.loading && <BP.Spinner className="e5spinwait" size={15} />}
								{!nilist.status.loading && <BP.Button
									text={E5UtilI18n._("download")} intent={BP.Intent.PRIMARY} autoFocus
									onClick={() => E5CBDashboard.DownloadNiListHealth("systemHealth", date, cla,
										E5CBSysOverview.clarankmap, this.props.toasterref)}
									onBlur={() => E5StoreCB.Ins().SetNiListSettings("none", "",
										undefined, undefined, undefined, undefined, undefined,
										undefined, undefined)} ref={this.props.downloadref} />}
							</div>} position={BP.Position.BOTTOM_LEFT}>
							<E5XYChart leftsource={E5CBDashboard.GetScores(E5StoreCBSys.Ins().overview.healthmetmap, E5CBSysOverview)}
								rightsource={{}}
								xoptions={{ xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0 }}
								title={_("cb-health-over-time")} loading={loading} height={350}
								clickcb={E5CBDashboard.HealthClick} withNewComponent chartOption={{ filled: true, newColorScheme: true }} />
						</BP.Popover>
					</div>
				</div>
			</div>
			<div className='e5line-20'>
				<div className="e5linefull">
					<E5AngularGauge gaugeinfo={
						{
							values: radarvalues, labels: [
								_("system-cpu"), _("system-mem"),
								_("system-reb"), _("system-temp"), _("system-proc"), _("system-flash"),
							],
							title: E5UtilI18n._("cb-sys-overview-specified-health-score"), loading: false
						}} isNewComponent customWrapperClass='custom'
						customChartClass='system-progress-chart'
						customListWidth='system-list-progress-charts'
						canvasSmall30 chartNewColorScheme/>
				</div>
			</div>
			<div className="e5line-20">
				<div className="e5linefull2">
					<E5XYChart helpcontent={<div className="e5cb-incidents-legend">{_("cb-incidents-help")}</div>}
						leftsource={E5CBSysOverview.GetIncidents(E5StoreCBSys.Ins().overview)} rightsource={{}}
						xoptions={{ xisdaytime: false, xisday: true, xtimezone: 0, holesizesec: 0 }}
						title={_("cb-inc-over-time")} subtitle={_("cb-incidents-sub")}
						loading={loading} height={350} withNewComponent
						chartOption={{ filled: false, colors: ['#9CCC65', '#AED581', '#19D6EF', '#7EE8F6', '#E25099', '#F472B6', '#F1C13B', '#FED86E', '#D4E157', '#DCE775', '#8D6E63', '#A1887F', '#E27C3E', '#FA923C']}} baseYaxis={true} />
				</div>
			</div>
			<div className="e5line-20">
				<div className="e5linefull">
					<div className="e5line-0">
						<BP.Popover
							className="e5linefull e5line-0" targetClassName="e5linefull"
							content={<div className="e5cb-dashboard-downloaddiv">
								<div className="title">{downloadstr}</div>
								{!nilist.status.loading &&
									<BP.Button text={_("download")} intent={BP.Intent.PRIMARY} autoFocus
										onClick={() => E5CBSysOverview.DownloadNiListIncid(incid,
											this.props.toasterref)}
										onBlur={() => E5StoreCB.Ins().SetNiListSettings("none", "",
											undefined, undefined, undefined, undefined,
											undefined, undefined, undefined)}
										ref={this.props.downloadref} />}
								{nilist.status.loading && <BP.Spinner className="e5spinwait" size={15} />}
							</div>} position={BP.Position.TOP_RIGHT} isOpen={mode === "incid"}>
							<E5PieChart pieinfo={{
								title: _("cb-pie-inc-cat"),
								loading,
								valueisseconds: true,
								labelisincident: true,
								ids: pieincinfo.incids,
								parents: pieincinfo.incparents,
								values: pieincinfo.incvalues,
								labels: pieincinfo.inclabels
							}} clickcb={E5CBSysOverview.PieClick} chartOption={{ type: 'sunburst' }} withNewComponent />
						</BP.Popover>
					</div>
				</div>
			</div>
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	static PieClick: E5PieChartClickCB = (id: string, label: string, parentlabel: string): void => {
		if (id.startsWith("I0")) {
			[id] = id.split(";");
			if (id.length > E5RequestMeta.Ins().h_incidentcateglen) {
				// bug in plotly, sometimes the parentlabel is the parent id !
				[parentlabel] = parentlabel.split(";");
				if (parentlabel.startsWith("I0") && parentlabel.length === E5RequestMeta.Ins().h_incidentcateglen)
					parentlabel = E5UtilI18n._("meta-wifi-incidentcateg-code-" + parentlabel);
				id = id.substr(0, E5RequestMeta.Ins().h_incidentcateglen + 2);
				E5StoreCB.Ins().SetNiListSettings("incid", E5Text.Substitute(
					E5UtilI18n._("cb-dashboard-downloadni-incid"),
					["1000", id, E5UtilI18n._("meta-wifi-incident-code-" + id), parentlabel]), true,
					"", id, "", "", "", "");
			}
		}
	};

	//E5
	static DownloadNiListIncid: (incid: string, ref: React.RefObject<BP.Toaster>) => void = (incid: string,
		ref: React.RefObject<BP.Toaster>): void =>
		E5RequestCBSys.FetchNiListIncid(E5StoreCB.Ins().filterinfo.populationid, incid,
			E5StoreCB.Ins().filterinfo.startdate, E5StoreCB.Ins().filterinfo.enddate,
			(status: E5RequestStatus) => {
				if (!status.success) ref.current?.show({
					message: E5UtilI18n._("cb-dashboard-downloaderr") + " : " + status.message,
					intent: BP.Intent.DANGER
				});
			});

	// ---------------- UTILS ----------------
	//E5
	static GetIncidents: (overview: E5StoreCBSysOverview) => E5XYSource =
		(overview: E5StoreCBSysOverview): E5XYSource => {
			if (!overview.metrics) return { numdatas: [], options: { stacked: false, markers: true, rangemode: "tozero" } };

			let incidents: E5XYSource =
				{ numdatas: undefined, options: { stacked: false, markers: true, rangemode: "tozero" } };
			incidents.numdatas = [];
			let catcnt: number = -1, inc: E5SysOverMetInc | undefined, cat: string, met: E5EntCBSysOverMet,
				crit: number | undefined, dur: number | undefined;
			for (cat of E5RequestMeta.Ins().h_incidentcategs) {
				if (!cat.startsWith("I03")) continue;
				catcnt++;
				incidents.numdatas.push({
					xaxisdata: [], yaxisdata: [], datalabel: E5UtilI18n._("meta-wifi-incidentcateg-code-" + cat)
						+ E5UtilI18n._("cb-wifi-incidents-criticality")
				});
				incidents.numdatas.push({
					xaxisdata: [], yaxisdata: [], datalabel: E5UtilI18n._("meta-wifi-incidentcateg-code-" + cat)
						+ E5UtilI18n._("cb-wifi-incidents-duration"), dotted: true
				});
				for (met of overview.metrics) {
					incidents.numdatas[catcnt * 2].xaxisdata.push(met.date);
					incidents.numdatas[catcnt * 2 + 1].xaxisdata.push(met.date);
					inc = met.inccatmap.get(cat);
					crit = inc?.crit;
					dur = inc?.dur;
					if (crit !== undefined) crit = Math.round(crit / 3600 * 10) / 10;
					if (dur !== undefined) dur = Math.round(dur / 3600 * 10) / 10;
					incidents.numdatas[catcnt * 2].yaxisdata.push(crit);
					incidents.numdatas[catcnt * 2 + 1].yaxisdata.push(dur);
				}
			}
			return incidents;
		};

	//E5
	static GetPieIncidentInfo(overview: E5StoreCBSysOverview, h_incidentcateglen: number): any {
		let incids: string[] = [], incparents: string[] = [], incvalues: number[] = [], inclabels: string[] = [];
		if (overview.inccritdist === null) {
			incids = ["N/A"];
			incparents = [""];
			incvalues = [0];
			inclabels = ["N/A"];
		} else if (overview.inccritdist !== undefined) {
			incids = ["total"];
			incparents = [""];
			incvalues = [0];
			inclabels = [E5UtilI18n._("total")];
			let catset: Set<string> = new Set(), inccode: string, inccrit: number | null, categ: string;
			for ([inccode, inccrit] of overview.inccritdist) {
				if (inccode === "") inccode = categ = "N/A";
				else categ = inccode.substr(0, h_incidentcateglen);
				if (!catset.has(categ)) {
					catset.add(categ);
					incids.push(categ);
					incparents.push("total");
					incvalues.push(0);
					inclabels.push(categ === "N/A" ? categ : E5UtilI18n._("meta-wifi-incidentcateg-code-" + categ));
				}
				incids.push(inccode + ";" + categ);
				incparents.push(categ);
				incvalues.push(inccrit);
				inclabels.push(inccode);
			}
		}
		return ({ incids: incids, incparents: incparents, incvalues: incvalues, inclabels: inclabels });
	}

	//E5
	static GetRadarValue(overview: E5StoreCBSysOverview): number[] {
		return [
			overview.cpuhealth ?? 0, overview.memhealth ?? 0,
			overview.rebhealth ?? 0, overview.temphealth ?? 0,
			overview.prochealth ?? 0, overview.flashhealth ?? 0,
		];
	}

	//E5
	static GetAvgHealthColor(radarvalues: number[]): string {
		let avghealth: number = radarvalues.reduce((acc, cur) => acc + cur, 0) / 7;
		return (avghealth < 0.2 ? E5Static.plotcolors.plotred : avghealth < 0.4 ?
			E5Static.plotcolors.plotorange : avghealth < 0.6 ? E5Static.plotcolors.plotyellow : avghealth < 0.8 ?
				E5Static.plotcolors.plotgreenlight : E5Static.plotcolors.plotgreendark) + "88";
	}
});
