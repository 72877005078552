import { E5EntHWanBytes } from "../entity/household/E5EntHWanBytes";
import { E5EntHWanMetric } from "../entity/household/E5EntHWanMetric";
import { E5UtilI18n } from "../global/E5MainLang";

export interface MetricChartDataInterface {
    title: string,
    color: string,
    data: Array<{ x: string, y: number }>
};

// sub optimal solution, but it works
export const E5ChartIcons = {
    dataView: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADLSURBVHgB7ZXdEcIgEISXTAqwA7ESkxLswA5sgU5sIVYQS9AKxA7SAR7DKS/8iU9m+Gb2SJhk9u4SAPh3RHBWmYGiRJorlND42kAZSfGBPJo05ky6wNzA40jaRWSRpJkTitIjlWEsO2VsnDgZaxKtpEM9d7gqN0hU0qOOG+lE2pMWvNvl2/ehtoID6QL3kzzZUIaqqKvA9fvo7429Poce/eUbFNEMmkEzSK9kybtmHoEtTLmB5nFGKYbfC2zZsSNTwh88pUxksGB1vAClUzDC+7iNyQAAAABJRU5ErkJggg==',
    zoomIn: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGWSURBVHgB7VTtUcMwDH3uMUBHSCegTNB0AtoJgAmACeJO0DJB0wmACRomIEzQjJANjGQ7X06cJhz9V909X2TJevqwA1zljIheq1QhrRFhTpgSckJKOECKGH8mkCqgdU8IbdBPQmZJeO/W6msiSjGKwAQ/6mAKG2zErsMntAmw75JIEowgOMFkekcHM9/BRiLAjHzzLreJc+jRZvXaCs42qaaVru1rS/ACj0wcfQXurTvAaiar5r7uf0J4xkCCBeGnI3igvxVla/RKlL4A7X0PAZeb1oJzxjyTo9YFtlqXal/6CH2bUCbhyI2j55akkITwpDM3wWPCl92vJ1WcPUvA2S9KzdyM2Ja/1cHbDyzUwT3vodki08+5ved1YaI3J/NiPg+ED3hEOAe43G+rLXvfgfF/h7lZM59v10ObW5LMS2IS4eAhuCoplhhUQZOEAwTg8hX1XpRDZBu3hUm4pfdkk4jEZjiBIQl4hRl6ULMw0YGw09VJxT6Rj6T/d90kY2Selsk+kv8RJpGK/r8qwsXEkJxwlTHyCzV3kZz/WmvIAAAAAElFTkSuQmCC',
    zoomOut: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAF+SURBVHgB7VTtVYMwFH3pcQBHwAmsExQm0E6gTqBOQJjAbtB2AnUCcQLZAEZgg3hfErCkSQCP/dd3zk0bcvPu+0hCdLYRE9FVqVKMObAELoEWqIA9SbGjPwtIlWDcAql1+gE0VoS/Xdv5GkIVzRIwzj+1M0UFFWLj4aQ2AOZmEClphkBNJtIbbGxCGweBEF2B2/poC2fTg43qJepcc/X62go8h2gLZ35HXNuJDbT1L4GnEOXCma+Ar6ETXQrXcdP/VzgAAo1nnidrNwNOtzpwzhnVR5Bq23MEdU4TmpBBa0U6K4FHz77SCarbOyrA0a/6mTkZO4pbqp0H7sOwREpfqKU95+Nm+nMPvIcoQwGho22IL5Gvucf2an+LEMF30fjd+bZCmfc+SMV1fyNTnhKcjCYL/Iqwg4Q4fYWjK/om8hqXhUW4pLdYk5SLYrqAEUl4JNP05GCFhfbARmcnFXPykEj8uR6KMZpAyWRM5H+MRaTC+6tyOpkZkZrONsd+AEW+gIRR2IxwAAAAAElFTkSuQmCC',
    reset: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAF5SURBVHgB1VXtVcIwFL3xMECdwDqBuAFMABtYJwAmaJnAOgFlAmEDnUCdADaADeJ9TWxDbT1J+QP3nNumyct7eV8pcO1Q3pKZnvI5IRfI1NF32w38sSeH5CeNxb6bBv+uZjqijzNoJPyKnZWcnMID3R6kes7njsrHfD+TjzBevJLJiZwJXwAyvSJ35MiZG5JJi2xCHrDUKbwggkZ5DF8Y44c2T1RDMIaEBbhnpewRAgmVQmr3VlXWzEFGFsHKBUslif8i5+50XUVSMcBTeYL+WFsdFVwPpMaPvU5fY0OO7GH/GBD3FjgHdewrA4PGYoGLhilX7U4NGgKJHb0H5UIUg1eKKfutu3Rapros0xWkF6Sbw/B72xbdBhTu7Ejum5CES/4iyze3o2sDprQ+SLncZjQ2gS9MOLeMgBzqlt+b+sytG8pcvHA156b1Ob3R/Ucz91JBPkAaSOOb0tIrEcdx5WGmxuhloDYkFZLAdLooj6whYR7y+7xO/ACN1n78tkmfMAAAAABJRU5ErkJggg==',
    download: 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADnSURBVHgB7VLREYIwFHv1HAA3YAMdASfQDdQNdALLBm4gG6gTOIKwARvIBjWFqgVKS/nzJHdpj/fShOsr0YjfwlHsS3pg4iMmRitw7XPEL2AAxoC/DuAiAh9gqFUDsNA0IXhvaHoGEOVgCH4NBM2wPj/msldpOsFsTZgslEkOLhGQ4oT8jlWdyjpn+bCAKiRSZqn62/fuNK8HcBFUOysMIVusZ61SKPPUoK356DO4gAmZwFmC6zk4zQ0+zSEH1IWYnbDuHOYtn2mjMS+fnR0baGx9+TCydoCgGyYiZ+IevB0ZvK40oi9euQhG9xpwPQUAAAAASUVORK5CYII=',
}

export function statusText(status: string) {
    switch (status.toLowerCase()) {
        case "up":
            return E5UtilI18n._("wifih-metric-status-up");
        case "down":
            return E5UtilI18n._("wifih-metric-status-down");
        default:
            return E5UtilI18n._("wifih-metric-status-disabled");
    }
};

export function statusClass(status: string) {
    switch (status.toLowerCase()) {
        case "up":
            return "e5-up-text";
        case "down":
            return "e5-off-text";
        default:
            return "e5-disabled-text";
    }
};

export function throughputChartData(data: E5EntHWanBytes[]): MetricChartDataInterface[] {
    if (!data?.length) {
        return [];
    }

    const downloadThroughtput: MetricChartDataInterface = {
        title: E5UtilI18n._('wifih-health-wan-interf-bytesreceived'),
        color: '#aed581',
        data: []
    }

    const uploadThroughtput: MetricChartDataInterface = {
        title: E5UtilI18n._('wifih-health-wan-interf-bytessent'),
        color: '#7ee8f6',
        data: []
    };

    let lastDate = data[0].time;

    data.forEach((byteData, index) => {
        const date = byteData.time;
        const currentDate = date;

        if (currentDate.diff(lastDate, 'seconds') > 45 && index !== 0) {
            lastDate = currentDate;
            
            downloadThroughtput.data.push(
                {
                    x: date.utc().format(),
                    y: E5EntHWanBytes.BytesToMbits(byteData.downbyterate)
                }
            )
    
            uploadThroughtput.data.push(
                {
                    x: date.utc().format(),
                    y: E5EntHWanBytes.BytesToMbits(byteData.upbyterate)
                }
            )
        }
    });

    return [downloadThroughtput, uploadThroughtput];
};

export function trafficMetricsChartData(data: E5EntHWanMetric[]): MetricChartDataInterface[] {
    if (!data?.length) {
        return [];
    }

    const avgTrafficResolution: MetricChartDataInterface = {
        title: E5UtilI18n._('wifih-metrics-group_avg'),
        color: '#aed581',
        data: []
    }

    const minTrafficResolution: MetricChartDataInterface = {
        title: E5UtilI18n._('wifih-metrics-group_min'),
        color: '#7ee8f6',
        data: []
    };

    const maxTrafficResolution: MetricChartDataInterface = {
        title: E5UtilI18n._('wifih-metrics-group_max'),
        color: '#f472b6',
        data: []
    };

    let lastDate = data[0].time;

    data.forEach((metricData, index) => {
        const date = metricData.time;
        const currentDate = date;

        if (currentDate.diff(lastDate, 'seconds') > 45 && index !== 0) {
            lastDate = currentDate;
            
            avgTrafficResolution.data.push(
                {
                    x: date.utc().format(),
                    y: metricData.avg
                }
            )
    
            minTrafficResolution.data.push(
                {
                    x: date.utc().format(),
                    y: metricData.min
                }
            )

            maxTrafficResolution.data.push(
                {
                    x: date.utc().format(),
                    y: metricData.max
                }
            )
        }
    });

    return [avgTrafficResolution, minTrafficResolution, maxTrafficResolution];
}

export function metricWanChartOptions(chartData: MetricChartDataInterface[], title: string, metric: string) {
    const legend = chartData.map((item) => item.title);
    const series = chartData.map((item, index) => {
        return {
            id: index,
            name: item.title,
            type: 'line',
            showSymbol: false,
            itemStyle: {
                color: item.color
            },
            emphasis: {
                focus: "series"
            },
            data: item.data.map((values) => [values.x, values.y, item.title]),
        }
    });

    return {
        title: {
            text: title,
            textStyle: {
                color: '#222221',
                fontSize: 18,
                fontWeight: 700,
                fontFamily: 'Montserrat',
            }
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: "line",
                label: {
                    backgroundColor: '#6a7985'
                }
            },
            valueFormatter: (value) => `${value} ${metric}`,
        },
        grid: {
            left: 0,
            right: 0,
            bottom: '10%',
            containLabel: true
        },
        toolbox: {
            right: 0,
            itemSize: 24,
            feature: {
                dataView: {
                    show: true,
                    readOnly: true,
                    icon: E5ChartIcons.dataView,
                    title: E5UtilI18n._("toolbox-dataView"),
                },
                dataZoom: {
                    yAxisIndex: "none",
                    title: {
                        zoom: E5UtilI18n._("toolbox-datazoom-zoom"),
                        back: E5UtilI18n._("toolbox-datazoom-back")
                    },
                    icon: {
                        zoom: E5ChartIcons.zoomIn,
                        back: E5ChartIcons.zoomOut,
                    },
                },
                restore: {
                    show: true,
                    icon: E5ChartIcons.reset,
                    title: E5UtilI18n._("toolbox-restore"),
                },
                saveAsImage: {
                    icon: E5ChartIcons.download,
                    title: E5UtilI18n._("toolbox-saveAsImage"),
                }
            },
        },
        xAxis: {
            type: 'time',
            boundaryGap: false,
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: `{value} ${metric}`,
            }
        },
        legend: {
            data: legend,
            bottom: 0,
            left: 0,
            icon: 'circle',
            textStyle: {
                color: '#222221',
                fontSize: 12,
                fontWeight: 500,
                fontFamily: 'Montserrat',
            }
        },
        series,
    }
}