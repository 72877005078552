import { E5RestRequest, E5RestResponder, E5RestResponse, E5RestSender } from "../util/E5RestSender";
import { E5RequestCallback, E5RequestStatus } from "./E5ServiceCommon";
import { E5EntAclGroup } from "../entity/E5EntAclGroup";
import { E5MainConfig } from "../global/E5MainConfig";
import { E5UtilI18n } from "../global/E5MainLang";
import { E5StoreACL } from "../store/E5StoreACL";
import { E5Storage } from "../util/E5Storage";
import { E5Request } from "./E5Request";
import {E5RequestCBPop} from "./E5RequestCBPop";
import {E5RequestAdmin} from "./E5RequestAdmin";

//E5
export enum E5RequestACLTags {
	LOAD_GROUPS = "load-groups",
	ADD_GROUP = "add-group",
	UPDATE_GROUP = "update-group",
	GET_GROUP = "get-group",
	DELETE_GROUP = "delete-group"
}

//E5
export class E5RequestACL implements E5RestResponder {

	// --------- STATIC -------------

	//E5
	private static ins: E5RequestACL;

	//E5
	static Ins(): E5RequestACL {
		if (E5RequestACL.ins === undefined) E5RequestACL.ins = new E5RequestACL();
		return E5RequestACL.ins;
	}

	// --------- INSTANCE -------------

	sender: E5RestSender;
	tokenmap: Map<string, number>;

	//E5
	constructor() {
		this.sender = new E5RestSender(this, E5MainConfig.GetBackendUrl() + "v1/group");
		this.tokenmap = new Map();
	}

	//E5
	RequestCallback(resp: E5RestResponse): void {
		if (resp.request.otherdata.requesttag === E5RequestACLTags.LOAD_GROUPS) this.LoadGroupsCB(resp);
		else if (resp.request.otherdata.requesttag === E5RequestACLTags.ADD_GROUP) this.AddGroupCB(resp);
		else if (resp.request.otherdata.requesttag === E5RequestACLTags.UPDATE_GROUP) this.UpdateGroupCB(resp);
		else if (resp.request.otherdata.requesttag === E5RequestACLTags.GET_GROUP) this.GetGroupCB(resp);
		else if (resp.request.otherdata.requesttag === E5RequestACLTags.DELETE_GROUP) this.DeleteGroupCB(resp);

	}

	//E5
	CancelTag(tag: E5RequestACLTags) {
		this.sender.Cancel(this.tokenmap.get(tag));
	}

	//E5
	ClearAll() {
		E5StoreACL.Ins().SetAclInfo({ loading: false, success: false, message: "" }, []);

		E5RequestACL.Ins().CancelTag(E5RequestACLTags.LOAD_GROUPS);
		E5RequestACL.Ins().CancelTag(E5RequestACLTags.ADD_GROUP);
		E5RequestACL.Ins().CancelTag(E5RequestACLTags.UPDATE_GROUP);
		E5RequestACL.Ins().CancelTag(E5RequestACLTags.GET_GROUP);
		E5RequestACL.Ins().CancelTag(E5RequestACLTags.DELETE_GROUP);
	}

	// --------- Load Groups -------------

	//E5
	LoadGroups(callback: E5RequestCallback): void {
		E5StoreACL.Ins().SetAclInfo({ loading: true, success: false, message: "" }, []);
		this.sender.Cancel(this.tokenmap.get(E5RequestACLTags.LOAD_GROUPS));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET", uri: "", header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: undefined, otherdata: { requesttag: E5RequestACLTags.LOAD_GROUPS, callback }
		};
		this.tokenmap.set(E5RequestACLTags.LOAD_GROUPS, this.sender.Send(req));
	}

	//E5
	LoadGroupsCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		}
		else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		try {
			let groups: E5EntAclGroup[] = [], idx: number;
			if (status.success && Array.isArray(resp.body)) for (idx = 0; idx < resp.body.length; idx++)
				groups.push(new E5EntAclGroup(resp.body[idx]));
			E5StoreACL.Ins().SetAclInfo(status, groups);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		resp.request.otherdata.callback?.(status);
	}

	// --------- Add Group -------------

	//E5
	AddGroup(group: E5EntAclGroup, callback: E5RequestCallback): void {
		this.sender.Cancel(this.tokenmap.get(E5RequestACLTags.ADD_GROUP));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "POST", uri: "", header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: group.CopyToCreate(), otherdata: { requesttag: E5RequestACLTags.ADD_GROUP, callback, group }
		};
		this.tokenmap.set(E5RequestACLTags.ADD_GROUP, this.sender.Send(req));
	}

	//E5
	AddGroupCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 500) {
			status.message = E5UtilI18n._("acl-existing-name-error");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 201) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		try {
			if (status.success)
				this.GetGroup(resp.request.otherdata.group.name, false, undefined, null);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		resp.request.otherdata.callback?.(status);
	}

	// --------- Update Group -------------

	//E5
	UpdateGroup(name: string, group: E5EntAclGroup, callback: E5RequestCallback): void {
		this.sender.Cancel(this.tokenmap.get(E5RequestACLTags.UPDATE_GROUP));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "PUT", uri: "/byname/" + name,
			header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: group.CopyToCreate(), otherdata: { requesttag: E5RequestACLTags.UPDATE_GROUP, callback, name, group }
		};
		this.tokenmap.set(E5RequestACLTags.UPDATE_GROUP, this.sender.Send(req));
	}

	//E5
	UpdateGroupCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		try {
			if (status.success) this.GetGroup(resp.request.otherdata.group.name, true, undefined,
				resp.request.otherdata.name);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		resp.request.otherdata.callback?.(status);
	}

	// --------- Get Group -------------

	//E5
	GetGroup(name: string, existing: boolean, callback: E5RequestCallback, oldname: string | null): void {
		E5StoreACL.Ins().SetAclInfo({ loading: true, success: false, message: "" },
			E5StoreACL.Ins().aclinfo.groups);
		this.sender.Cancel(this.tokenmap.get(E5RequestACLTags.GET_GROUP));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "GET", uri: "/byname/" + name,
			header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: undefined, otherdata: { requesttag: E5RequestACLTags.GET_GROUP, callback, existing, oldname }
		};
		this.tokenmap.set(E5RequestACLTags.GET_GROUP, this.sender.Send(req));
	}

	//E5
	GetGroupCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		let getgroup: E5EntAclGroup = new E5EntAclGroup();
		try {
			let groups: E5EntAclGroup[] = E5StoreACL.Ins().aclinfo.groups;
			if (status.success) {
				getgroup = new E5EntAclGroup(resp.body);
				if (resp.request.otherdata.existing) groups = groups.map(group =>
					(resp.request.otherdata.oldname !== null && group.name === resp.request.otherdata.oldname) ||
						group.name === getgroup.name ? getgroup : group);
				else groups = [...groups, getgroup];
			}
			E5StoreACL.Ins().SetAclInfo(status, groups);
			E5RequestCBPop.Ins().FetchPops(undefined);
			E5RequestAdmin.Ins().LoadUsers(undefined);
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		resp.request.otherdata.callback?.(status, getgroup);
	}

	// --------- Delete Group -------------

	//E5
	async DeleteGroup(name: string, callback: E5RequestCallback): void {
		// this.sender.Cancel(this.tokenmap.get(E5RequestACLTags.DELETE_GROUP));
		let bearer: string | undefined = E5Storage.GetLSString(E5MainConfig.ls_bearer);
		let req: E5RestRequest = {
			method: "DELETE", uri: "/byname/" + name,
			header: [["Content-Type", "application/json"], ["Authorization", bearer]],
			body: undefined, otherdata: { requesttag: E5RequestACLTags.DELETE_GROUP, callback, name }
		};
		await this.tokenmap.set(E5RequestACLTags.DELETE_GROUP, this.sender.Send(req));
	}

	//E5
	DeleteGroupCB(resp: E5RestResponse): void {
		let status: E5RequestStatus = { loading: false, success: false, message: "" };
		if (resp.status === 502 || resp.status === 503 || resp.status === 504 || resp.status === 0) {
			status.message = E5UtilI18n._("servernotresponding");
		} else if (resp.status === 500) {
			status.message = E5UtilI18n._("acl-in-use-cannot-delete-error");
		} else if (resp.status === 401) {
			E5Request.Ins().SessionExpired();
		} else if (resp.status === 400) {
			status.message = E5UtilI18n._("formaterror") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 403) {
			status.message = E5UtilI18n._("operation-forbidden") + " : " + E5Request.GetJsonErrors(resp.body);
		} else if (resp.status === 409) {
			status.message = E5UtilI18n._("admin-acl-delete-blocked");
		} else if (resp.status !== 200) {
			status.message = E5UtilI18n._("processingerror");
		} else status.success = true;
		try {
			if (status.success) E5StoreACL.Ins().SetAclInfo(status,
				E5StoreACL.Ins().aclinfo.groups.filter(group => group.name !== resp.request.otherdata.name));
		} catch (ex) {
			if (E5MainConfig.GetDevMode()) console.log(ex);
		}
		resp.request.otherdata.callback?.(status);
	}
}
