import React from "react";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
import Plot, { PlotParams } from "react-plotly.js";
import { Layout, PlotData, PlotMouseEvent } from "plotly.js";
//
import { E5StorePieInfo } from "../../request/E5ServiceCommon";
import { E5RequestMeta } from "../../request/E5RequestMeta";
import { E5Store } from "../../store/E5Store";
import { E5Text } from "../../util/E5Text";
import { E5UtilI18n } from "../E5MainLang";
import ReactECharts from 'echarts-for-react';

//
import "./E5PieChart.css";

//E5
export type E5PieChartClickCB = (id: string, label: string, parentlabel: string, value: number) => void;

//E5
interface E5PieChartState { }

export interface E5XYChartOption {
	type?: string;
	filled?: boolean;
	colors?: string[];
	parentId?: string;
	containerClass?: string;
}

//E5
interface E5PieChartProps {
	pieinfo: E5StorePieInfo;
	clickcb?: E5PieChartClickCB;
	helpcontent?: JSX.Element;
	chartOption?: E5XYChartOption;
	withNewComponent?: boolean;
}

//E5
export const E5PieChart = observer(class E5PieChart extends React.PureComponent<E5PieChartProps, E5PieChartState> {

	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		const { ids, parents, labels } = this.props.pieinfo
		// compute values for parents
		let values: number[] = this.props.pieinfo.values.map((val: number) => val);//duplicate values array
		let idx: number, idx2: number;
		for (idx = this.props.pieinfo.parents.length - 1; idx >= 0; idx--) {
			for (idx2 = 0; idx2 < this.props.pieinfo.ids.length; idx2++)
				if (this.props.pieinfo.ids[idx2] === this.props.pieinfo.parents[idx]) {
					values[idx2] += values[idx];
				}
		}
		// compute texts if needed
		let texts: string[] = []
		let sum: number = 0;
		for (idx = 0; idx < this.props.pieinfo.ids.length; idx++) {
			let val: number = values[idx];
			sum += val;
			let id: string = this.props.pieinfo.ids[idx];
			let txt: string = "";
			if (this.props.pieinfo.labelisincident) {
				[id] = id.split(";");//separate incident code/categ from access type in cust base wan tab pie
				if (id.startsWith("I0") && id.length > E5RequestMeta.Ins().h_incidentcateglen)
					txt += "<br>" + E5UtilI18n._("meta-wifi-incident-code-" + id);
			}
			if (this.props.pieinfo.valueisseconds) {
				if (this.props.pieinfo.showonlyhours === true) txt += "<br>" + E5Text.Seconds2H_str(val);
				else txt += "<br>" + E5Text.Seconds2DHMS_str(val);
			}
			texts.push(txt);
		}
		//let hover: string = "label+value+percent+percent parent+percent root";
		let unit: string = "";
		if (this.props.pieinfo.valueisseconds)
			unit = "sec";
		let hover: string = "%{label} : %{value:.2f} " + unit;
		if (this.props.pieinfo.valueisseconds || this.props.pieinfo.roundvalues === true)
			hover = "%{label} : %{value} " + unit;
		if (this.props.pieinfo.valsuffix !== undefined) hover += this.props.pieinfo.valsuffix;
		if (this.props.pieinfo.valueisseconds || this.props.pieinfo.labelisincident)
			hover += "%{text}";
		// hover is different if sum is 0
		if (sum > 0) {
			hover +=
				"<br>%{percentParent:.1%} " + E5UtilI18n._("piefromparent") + " %{parent}" +
				"<br>%{percentRoot:.1%} " + E5UtilI18n._("piefromparent") + " %{root}" +
				"<extra></extra>";
		}
		//"<br>entry:%{entry}<br>root:%{root}<br>parent:%{parent}<br>currentPath:%{currentPath}<br>"+
		//
		let helpbutton: JSX.Element = <></>;
		if (this.props.helpcontent !== undefined) {
			helpbutton = <BP.Popover content={this.props.helpcontent} position={BP.Position.BOTTOM_LEFT}>
				<BP.Button icon="help" small={true} />
			</BP.Popover>;
		}
		//
		let graphdata: PlotParams = {
			data: [
				{
					type: "sunburst",
					ids: this.props.pieinfo.ids,
					parents: this.props.pieinfo.parents,
					values: values,
					text: texts,
					labels: this.props.pieinfo.labels,
					hovertemplate: hover,
					marker: { line: { color: "black", width: 1 } },
					textfont: { size: 24, color: "black" },
					textinfo: "label",
					branchvalues: "total",
					hoverlabel: { bgcolor: "white", bordercolor: "black", font: { size: 15, color: "black" } },
					insidetextfont: { size: 12, color: "black" },
					insidetextorientation: "auto",
					leaf: { opacity: 0.75 }
				} as any as PlotData // because sunburst strangely does not exist in @types package
			],
			layout: {
				font: { family: "montserrat" },
				width: this.props.pieinfo.width === undefined ? 350 : this.props.pieinfo.width,
				height: this.props.pieinfo.height === undefined ? 350 : this.props.pieinfo.height,
				margin: { t: 10, r: 10, l: 10, b: 10 },
				sunburstcolorway: ["#636efa", "#00cc96"],
				paper_bgcolor: "#00000000"
			} as any as Layout, // because sunburst strangely does not exist in @types package
			config: { displayModeBar: false }, onClick: this.Click
		};

		let sunburstOption;
		const option = {
			tooltip: {
				trigger: 'item'
			},
			legend: {
				show: false,
				bottom: '5%',
				left: 'center'
			},
			series: [
				{
					name: this.props.pieinfo.title,
					type: 'pie',
					radius: ['40%', '80%'],
					avoidLabelOverlap: false,
					itemStyle: {
						borderRadius: 10,
						borderColor: '#fff',
						borderWidth: 2
					},
					label: {
						position: 'inner',
						color: 'white',
						fontSize: 10,
						fontWeight: 'bold'
					},
					emphasis: {},
					labelLine: {
						show: false
					},
					data: this.props.pieinfo.labels.map((name, index) => {
						const value = this.props.pieinfo.values[index]
						const colors = ['', ...(this.props.chartOption?.colors || [])]
						return {
							value,
							name: value ? name : '',
							itemStyle: colors?.length ? { color: colors[index] } : null,
						}
					})
				}
			]
		};
		//@ts-ignore
		const getChildren = (label: string, depth: number) => {
			return ids.filter((id: string, idIdx: number) => parents[idIdx] === label).map((id: string) => {
				const idIndex = ids.findIndex(value => value === id);
				const value = values[idIndex] || null;
				const { colors } = this.props.chartOption || {};
				return {
					name: labels[idIndex],
					value,
					label: { width: 70, overflow: 'truncate' },
					itemStyle: colors ? { color: colors[depth] } : null,
					children: getChildren(id, depth + 1),
				}
			})
		}

		if (this.props.chartOption?.type === 'sunburst') {
			const data = getChildren(this.props.chartOption.parentId || ids[0] || 'total', 0);
			sunburstOption = {
				series: {
					type: 'sunburst',
					data: data,
					radius: [60, '95%'],
					emphasis: {
						focus: 'ancestor'
					},
					itemStyle: {
						borderRadius: 7,
						borderWidth: 2
					},
					label: {
						position: 'inner',
						rotate: 'tangential',
						fontSize: 14,
						fontWeight: 'bold',
						color: 'white'
					},
					labelLayout: {
						hideOverlap: true,
					},
				},
				tooltip: {}
			};
		}

		if (!values[0]) {
			return <div className="e5compo e5pie-chart no-data-pie">
				<div className="e5line-5">
					<div className="e5compotitle">{this.props.pieinfo.title}
						{this.props.pieinfo.loading && <BP.Spinner className="e5spinwait" size={15} />}
					</div>
				</div>
				{this.props.pieinfo.subtitle !== undefined && <div>{this.props.pieinfo.subtitle}</div>}
				{!this.props.pieinfo.loading &&
					<div className="e5line-0 no-data">
						<span >{E5UtilI18n._("no-data-collected")}</span>
					</div>
				}
			</div>;
		}

		return <div className="e5compo e5pie-chart">
			<div className="e5line-5">
				<div className="e5compotitle">{this.props.pieinfo.title}
					{this.props.pieinfo.loading && <BP.Spinner className="e5spinwait" size={15} />}
				</div>
				{helpbutton}
			</div>
			{this.props.pieinfo.subtitle !== undefined && <div>{this.props.pieinfo.subtitle}</div>}
			{!this.props.pieinfo.loading &&
				<div className="e5line-0">
					<div className="e5linefull" />

					{this.props.withNewComponent ? <ReactECharts option={sunburstOption || option} /> : <Plot className={this.props.clickcb !== undefined ? "clickable-pie" : ""} {...graphdata} />}
					<div className="e5linefull" />
				</div>
			}
		</div>;
	}

	// ---------------- EVENTS ----------------

	//E5
	Click = (event: PlotMouseEvent): void => {
		if (event.points.length > 0) {
			let pt: any = event.points[0];
			if (pt.id !== undefined && pt.parent !== undefined && pt.label !== undefined && pt.value !== undefined)
				this.props.clickcb?.(pt.id, pt.label, pt.parent, pt.value);
		}
	};
});
