import React from 'react';
import { Route } from "react-router";
import { observer } from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import { E5LinkMenuItem } from "./component/E5LinkMenuItem";
import { E5Page, E5Path } from "./E5MainStatics";
import { E5MainConfig } from "./E5MainConfig";
import { E5Store } from "../store/E5Store";
import { E5UtilI18n } from "./E5MainLang";
import { E5Text } from "../util/E5Text";
//
import { ReactComponent as CB_DASHBOARD } from '../assets/menu/cb-dashboard.svg'
import { ReactComponent as CB_POPULATIONS } from '../assets/menu/cb-population.svg'
import { ReactComponent as CB_RDM } from '../assets/menu/cb-rdm.svg'

import { ReactComponent as H_DASHBOARD } from '../assets/menu/h-dashboard.svg'
import { ReactComponent as H_EQUIPEMNT } from '../assets/menu/h-equipment.svg'
import { ReactComponent as H_CONNECTIVITY } from '../assets/menu/h-connectivity.svg'
import { ReactComponent as H_EVENTLOGS } from '../assets/menu/h-eventlog.svg'
import { ReactComponent as H_INCIDENTS } from '../assets/menu/h-incidents.svg'
import { ReactComponent as H_METRICS } from '../assets/menu/h-metrics.svg'
import { ReactComponent as H_STATIONS } from '../assets/menu/h-stations.svg'

import { ReactComponent as Users } from '../assets/menu/users.svg'
import { ReactComponent as ACL } from '../assets/menu/acl.svg'


//
import "./E5MainMenu.css";

//E5
interface E5MainMenuState {
	narrow: boolean;
}

//E5
interface E5MainMenuProps { }

//E5
export const E5MainMenu = observer(class E5MainMenu extends React.PureComponent<E5MainMenuProps, E5MainMenuState> {

	//E5
	constructor(props: E5MainMenuProps, state: E5MainMenuState) {
		super(props, state);
		this.state = { narrow: false };
	}

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

		let { _ } = E5UtilI18n, { narrow } = this.state, { IsAllowed } = E5Path;

		return <Route path={"/*"} render={(props) =>
			<BP.Menu className={"e5mainmenu e5column-10" + (narrow ? " narrow" : "")}>
				<div className="e5line-2">
					<div className="e5linefull" />
					<BP.Tooltip position={BP.Position.BOTTOM_RIGHT}
						content={_("mainmenu-" + (narrow ? "expand" : "collapse") + "tooltip")}>
						<img className={`wificb-dashboard-search-svg ${narrow ? 'main-toggle-close' : 'main-toggle-open'}`} src={`./img/v3/menu/${narrow ? "chevron-left" : "chevron-right"}.svg`} onClick={this.ToggleMenu} />
					</BP.Tooltip>
				</div>
				<div className="e5mainmenucontent e5columnfull">
					<div>
						{(IsAllowed(E5Page.CB_DASHBOARD) || IsAllowed(E5Page.CB_POPULATIONS)) && <>
							<E5LinkMenuItem {...props} classname="e5categmenuitem" disabled
								narrow={narrow} text={_("mainmenu-customer-base")} />
							{IsAllowed(E5Page.CB_DASHBOARD) && <E5LinkMenuItem
								{...props} page={E5Page.CB_DASHBOARD} imageurl="/img/v3/menu/dashboard.svg"
								narrow={narrow} text={_("mainmenu-cb-dashboard")} Icon={CB_DASHBOARD} />}
							{IsAllowed(E5Page.CB_POPULATIONS) && <E5LinkMenuItem
								{...props} page={E5Page.CB_POPULATIONS} imageurl="/img/v3/menu/populations.svg"
								narrow={narrow} text={_("mainmenu-cb-populations")} Icon={CB_POPULATIONS} />}
							{IsAllowed(E5Page.MANAGEMENT_RDM) && E5MainConfig.GetRdmEnabled() && <E5LinkMenuItem
								{...props} page={E5Page.MANAGEMENT_RDM} imageurl="/img/v3/menu/rdm.svg"
								narrow={narrow} text={_("mainmenu-m-rdm")} Icon={CB_RDM} />}
						</>}

						{(IsAllowed(E5Page.H_DASHBOARD) || IsAllowed(E5Page.H_INCIDENTS) ||
							IsAllowed(E5Page.H_CONNECTIVITY) || IsAllowed(E5Page.H_METRICS)) && <>
								<E5LinkMenuItem {...props} classname="e5categmenuitem" disabled
									narrow={narrow} text={_("mainmenu-household")} />
								{IsAllowed(E5Page.H_DASHBOARD) && <E5LinkMenuItem
									{...props} page={E5Page.H_DASHBOARD} imageurl="/img/v3/menu/home.svg"
									narrow={narrow} text={_("mainmenu-h-dashboard")} Icon={H_DASHBOARD} />}
								{IsAllowed(E5Page.H_DASHBOARD) && <E5LinkMenuItem
									{...props} page={E5Page.EQUIPMENTS} imageurl="/img/v3/menu/home.svg"
									narrow={narrow} text={_("mainmenu-h-equipments")} Icon={H_EQUIPEMNT} />}
								{IsAllowed(E5Page.H_DASHBOARD) && <E5LinkMenuItem
									{...props} page={E5Page.STATIONS} imageurl="/img/v3/menu/home.svg"
									narrow={narrow} text={'Stations'} Icon={H_STATIONS} />}
								{IsAllowed(E5Page.H_INCIDENTS) && <E5LinkMenuItem
									{...props} page={E5Page.H_INCIDENTS} imageurl="/img/v3/menu/incidents.svg"
									narrow={narrow} text={_("mainmenu-h-incidents")} Icon={H_INCIDENTS} />}
								{IsAllowed(E5Page.H_CONNECTIVITY) && E5MainConfig.GetWifiEnabled() && <E5LinkMenuItem
									{...props} page={E5Page.H_CONNECTIVITY} imageurl="/img/v3/menu/connectivity.svg"
									narrow={narrow} text={_("mainmenu-h-connectivity")} Icon={H_CONNECTIVITY} />}
								{IsAllowed(E5Page.H_METRICS) && <E5LinkMenuItem
									{...props} page={E5Page.H_METRICS} imageurl="/img/v3/menu/metrics.svg"
									narrow={narrow} text={_("mainmenu-h-metrics")} Icon={H_METRICS} />}
								{IsAllowed(E5Page.H_EVENTLOGS) && <E5LinkMenuItem
									{...props} page={E5Page.H_EVENTLOGS} imageurl="/img/v3/menu/eventlogs.svg"
									narrow={narrow} text={_("mainmenu-h-eventlogs")} Icon={H_EVENTLOGS} />}
							</>}

						{(IsAllowed(E5Page.MANAGEMENT_USERS) || IsAllowed(E5Page.MANAGEMENT_RDM) ||
							IsAllowed(E5Page.MANAGEMENT_ACL)) && <>
								<E5LinkMenuItem {...props} classname="e5categmenuitem" disabled
									narrow={narrow} text={_("mainmenu-management")} />
								{IsAllowed(E5Page.MANAGEMENT_USERS) && <E5LinkMenuItem
									{...props} page={E5Page.MANAGEMENT_USERS} imageurl="/img/v3/menu/users.svg"
									narrow={narrow} text={_("mainmenu-m-users")} Icon={Users} />}

								{IsAllowed(E5Page.MANAGEMENT_ACL) && <E5LinkMenuItem
									{...props} page={E5Page.MANAGEMENT_ACL} imageurl="/img/v3/menu/acl-group.svg"
									narrow={narrow} text={_("mainmenu-m-acl")} Icon={ACL} />}
							</>}
					</div>

					{/*{"TESTS"}*/}

					{E5MainConfig.GetDevMode() && <div>
						<E5LinkMenuItem {...props} classname="e5categmenuitem" disabled
							narrow={narrow} text={_("mainmenu-test")} />
						{[
							[E5Page.T_XY_CHART, "xy-chart"], [E5Page.T_PIE_CHART, "pie"],
							[E5Page.T_RADAR_CHART, "radar"], [E5Page.T_ANGULAR_GAUGE, "angular-gauge"],
							[E5Page.T_BULLET_GAUGE, "bullet-gauge"], [E5Page.T_CB_POPULATION, "populations"],
							[E5Page.T_H_INDIC, "h-indics"], [E5Page.T_H_INCID_RECOM, "incids-recoms"],
							[E5Page.T_H_TOPO_ITEM, "topo-items"], [E5Page.T_H_TOPO_GRAPH, "topo-graph"],
							[E5Page.T_H_CONNECTION, "connections"], [E5Page.T_H_HEATMAP, "heatmap"],
							[E5Page.T_ADMIN_USER, "admin-users"]
						].map((pagetext, idx) =>
							<E5LinkMenuItem {...props} page={pagetext[0]} narrow={narrow} key={idx}
								text={_("mainmenu-test-" + pagetext[1])} />)}
					</div>}
				</div>

				{!narrow && <div className="e5menucopyright">
					{_("mainmenu-copyright")}
					<br />{E5Text.Substitute(_("mainmenu-frontversion"), [E5MainConfig.GetFrontVersion()])}
					<br />{E5Text.Substitute(_("mainmenu-cmsversion"), [E5MainConfig.GetCmsVersion()])}
				</div>}
			</BP.Menu>
		} />;
	}

	//E5
	ToggleMenu = (): void => this.setState({ narrow: !this.state.narrow });
});
