import { Icon, Popover } from "@blueprintjs/core";
import { observer } from "mobx-react"
import { useEffect, useState } from "react";
import { E5EntHEventLog } from "../../../entity/household/eventlog/E5EntHEventLog";
import { E5PageTitle } from "../../../global/component/E5PageTitle";
import SelectStyled from "../../../global/component/SelectStyled";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5Store } from "../../../store/E5Store";
import { E5StoreH } from "../../../store/E5StoreH";
import { E5HFilters } from "../E5HFilters";

import "./E5HEventLogs.css";
import E5HEventLogsTable from "./E5HEventLogsTable";

interface E5HEventlogProps {
    location: any;
}

export const E5HEventLogs = observer(function E5HEventLogs(props: E5HEventlogProps) {
    // force rerender when lang changes
    const curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

    const { langinfo: languageInfo, pageinfo: pageInfo } = E5Store.Ins();
    const { searchniinfo: searchNiInfo, searchniautoinfo: searchNiAutoInfo, eventlogsinfo } = E5StoreH.Ins();

    const [logs, setLogs] = useState([] as E5EntHEventLog[]);
    const [devicesInfo, setDevicesInfo] = useState([] as { value: string, label: JSX.Element | string }[]);
    const [selectedImeis, setSelectedImeis] = useState<string[]>([]);
    const [filteredLogs, setFilteredLogs] = useState([] as E5EntHEventLog[]);
    const [eventType, setEventType] = useState<string>('');

    const eventTypeOptions = [
        { value: '0', label: E5UtilI18n._("cb-eventlog-connectivity") },
        { value: '1', label: E5UtilI18n._("cb-eventlog-band-steering") },
        { value: '2', label: E5UtilI18n._("cb-eventlog-mesh-instability") },
        { value: '3', label: E5UtilI18n._("cb-eventlog-missing-data") },
    ];


    useEffect(() => {
        const uniqueDevices = Array.from(new Set(eventlogsinfo.eventLogs.map(({ deviceImei }) => deviceImei)));
        const devicesInfo = uniqueDevices.map((item) => {
            return {
                imei: item,
                type: eventlogsinfo.eventLogs.find((log) => log.deviceImei === item)?.nodeType
            }
        });

        setLogs(eventlogsinfo.eventLogs);
        setFilteredLogs(eventlogsinfo.eventLogs);
        setDevicesInfo(menuOptions(devicesInfo));

        if (props.location.search) {
            const params = new URLSearchParams(props.location.search);
            const equip = params.get('equip');
            if (equip) {
                setSelectedImeis([equip]);
                setFilteredLogs(eventlogsinfo.eventLogs.filter((eventLog) => eventLog.deviceImei === equip));
            } else {
                setSelectedImeis([]);
            }
        }

    }, [eventlogsinfo.eventLogs, props.location.search]);

    const menuOptions = (devices: {
        imei: string;
        type?: string;
    }[]): {
        value: string;
        label: JSX.Element | string;
    }[] => {
        if (devices.length === 0) {
            return [
                { value: '', label: E5UtilI18n._("cb-eventlog-select-equipment") }
            ];
        }

        const menu = devices.map((item) => {
            const image = item.type === 'EXT' ? './img/v3/metrics/extender.svg' : './img/v3/metrics/gateway.svg';
            return {
                value: item.imei,
                label: (
                    <div className='e5h-eventlogs-select'>
                        <img src={image} className='e5h-eventlogs-ext' alt={item.type} />
                        {item.imei}
                    </div>
                )
            }
        });

        return menu;
    };

    const changeDeviceFilter = (event: string[]) => {
        if (event.length === 1 && event[0] === '') {
            setSelectedImeis([]);
        } else {
            setSelectedImeis(event);
        }

        filterLogs(logs, eventType, event);
    }

    const changeEventTypeFilter = (event: string) => {
        setEventType(event);

        filterLogs(logs, event, selectedImeis);
    }

    const filterLogs = (logs: E5EntHEventLog[], eventType: string, imeis: string[]) => {
        const filtered = logs.filter((eventLog) => {
            if (eventType !== '') {
                return eventLog.eventType === eventType;
            }
            return true;
        }).filter((eventLog) => {
            if (imeis.length) {
                return imeis.includes(eventLog.deviceImei);
            }
            return true;
        });

        setFilteredLogs(filtered);
    }

    const mapLogsTable = (logs: E5EntHEventLog[]) => {
        return logs.map((eventLog) => {
            const trimmedArgs: any = {}
            Object.entries(eventLog.args).forEach(([key, value]) => {
                trimmedArgs[key.trim()] = value.trim()
            })
            return {
                eventTime: eventLog.eventTime,
                deviceImei: eventLog.deviceImei,
                eventType: eventLog.eventType,
                ...trimmedArgs
            }
        });
    }

    const popOverContent = () => {
        return (
            <div className="e5h-eventlogs-refresh-popover">
                {E5UtilI18n._("cb-eventlog-refresh")}
            </div>
        )
    }

    return (
        <div className="e5page e5columnfull e5column-20 e5h-eventlogs">
            <div className="e5column-5">
                <E5PageTitle titlekey="pagetitle-h-eventlogs" />
            </div>

            <E5HFilters langinfo={languageInfo} searchniinfo={searchNiInfo}
                pageinfo={pageInfo} searchniautoinfo={searchNiAutoInfo} status={eventlogsinfo.status} />

            <div className="e5flexcenterend filter">
                {
                    eventType !== '' ?
                        <div className="refreshIconContainer">
                            <Popover
                                content={popOverContent()}
                                position='top'
                                interactionKind='hover-target'
                            >
                                <Icon icon='refresh' className='refreshIcon' onClick={() => changeEventTypeFilter('')}/>
                            </Popover>
                        </div>
                    : null
                }


                <SelectStyled
                    onChange={(event) => changeEventTypeFilter(event)}
                    label={E5UtilI18n._("cb-eventlog-filter-by-event-type")}
                    value={eventType}
                    defaultValue={''}
                    data={eventTypeOptions}
                />

                <SelectStyled
                    onChange={(event) => changeDeviceFilter(event)}
                    label={E5UtilI18n._("cb-eventlog-filter-by-equipment")}
                    value={selectedImeis}
                    defaultValue={''}
                    data={devicesInfo}
                    className={'e5h-eventlogs-select'}
                    multiple
                />
            </div>

            <E5HEventLogsTable data={mapLogsTable(filteredLogs)} eventType={eventType} />
        </div>
    )
});