import React from 'react'
import { observer } from "mobx-react";
//
import { E5StoreH } from "../../../store/E5StoreH";
import { E5PageTitle } from "../../../global/component/E5PageTitle";
import { E5Store } from "../../../store/E5Store";
import { E5HFilters } from "../E5HFilters";
import E5HEquipmentItem from "./E5HEquipmentItem"
import './E5HEquipmentsPage.css'
import { Spinner } from '@blueprintjs/core';

const E5HEquipmentsPage = observer(() => {
    // force reload when language changes
    const curlang = E5Store.Ins().langinfo.curlang; // eslint-disable-line
    const { searchniautoinfo, searchniinfo, equipinfo, equipmentRdmInfo, indicglobalinfo, indicsysinfo, stationinfo } = E5StoreH.Ins();
    const { langinfo, pageinfo } = E5Store.Ins();
    const statusPage = equipinfo.status.message !== "" ? equipinfo.status : equipmentRdmInfo.status;

    return <div className="e5page e5wifih-metrics e5columnfull e5column-20 equipments-page">
        <div className="e5column-5">
            <E5PageTitle titlekey="pagetitle-h-equipments" />
        </div>
        <E5HFilters langinfo={langinfo} searchniinfo={searchniinfo} searchniautoinfo={searchniautoinfo} pageinfo={pageinfo} status={statusPage} systeminfo />

        {equipinfo.loading || indicglobalinfo.loading || indicsysinfo.status.loading ?
            <div className="e5line-20 e5hloading">
                <Spinner className="e5spinwait" size={30} />
            </div>
            :
            equipinfo.equips.map((equip, index) => {
                const connectedStations = stationinfo.stations.filter((station) => station.connectedStation === equip.imei);
                const equipmentActions = equipmentRdmInfo.actions.filter((action) => action.deviceId === equip.imei);

                return <E5HEquipmentItem
                    equip={equip}
                    key={index}
                    indicglobalinfo={indicglobalinfo}
                    indicsysinfo={indicsysinfo}
                    loading={indicsysinfo.status.loading}
                    connectedStations={connectedStations}
                    actions={equipmentActions}
                />
            })
        }
    </div>
})

export default E5HEquipmentsPage;