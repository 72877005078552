import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
//
import {E5StoreHIncidentSelIdx, E5StoreHIncidents, E5StoreHIndicGlobal, E5StoreH} from "../../../store/E5StoreH";
import {E5PageTitle} from "../../../global/component/E5PageTitle";
import {E5UtilI18n} from "../../../global/E5MainLang";
import {E5HIncIncident} from "./E5HIncIncident";
import {E5Store} from "../../../store/E5Store";
import {E5HIncAnomaly} from "./E5HIncAnomaly";
import {E5HFilters} from "../E5HFilters";
//
import "./E5HIncident.css";

//E5
interface E5HIncidentState {}

//E5
interface E5HIncidentProps {
	selincidentinfo: E5StoreHIncidentSelIdx;
	incidentinfo: E5StoreHIncidents;
	indicglobalinfo: E5StoreHIndicGlobal;
}

//E5
export const E5HIncident = observer(class E5HIncident extends React.PureComponent<E5HIncidentProps, E5HIncidentState> {

	//E5
	render(): JSX.Element {
		// force rerender when lang changes
		let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line
		const status = this.getStatusPage();

		return <div className="e5page e5wifih-incident e5columnfull e5column-20">
			<div className="e5column-5">
				<E5PageTitle titlekey="pagetitle-h-incidents"/>
			</div>

			<E5HFilters langinfo={E5Store.Ins().langinfo} searchniinfo={E5StoreH.Ins().searchniinfo}
						searchniautoinfo={E5StoreH.Ins().searchniautoinfo} pageinfo={E5Store.Ins().pageinfo} status={status} withIncidents/>

			<E5HIncIncident langinfo={E5Store.Ins().langinfo}/>
		</div>;
	}

	getStatusPage = () => {
		if (E5StoreH.Ins().incidentinfo.status.message !== "") {
			return E5StoreH.Ins().incidentinfo.status;
		} else if (E5StoreH.Ins().equipinfo.status.message !== "") {
			return E5StoreH.Ins().equipinfo.status;
		} else if (E5StoreH.Ins().stationinfo.status.message !== "") {
			return E5StoreH.Ins().stationinfo.status;
		} else {
			return E5StoreH.Ins().searchniinfo.status;
		}
	}
});
