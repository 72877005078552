import React from "react";
import {observer} from "mobx-react";
import * as BP from "@blueprintjs/core";
import {RouteComponentProps} from "react-router";
//
import {E5AdminUsersRolesLegend} from "../admin/user/E5AdminUsersRolesLegend";
import {E5Page, E5Path} from "../../global/E5MainStatics";
import {E5MainConfig} from "../../global/E5MainConfig";
import {E5Store} from "../../store/E5Store";
import {ReactComponent as HelpIcon} from '../../assets/help.svg'

// import SwaggerUI from "swagger-ui-react"
// import "swagger-ui-react/swagger-ui.css"
//
import "./E5WelcomePage.css";
import { E5UtilI18n } from "../../global/E5MainLang";

//E5
enum E5WelcomeSubPage {
	home = "home", howitworks = "howitworks", developers = "developers"
}

//E5
interface E5WelcomePageState {
	subpage: string;
}

//E5
interface E5WelcomePageProps {
	activePage?: string;
}

//E5
export const E5WelcomePage = observer(class E5WelcomePage extends React.PureComponent
	<RouteComponentProps<{}>&E5WelcomePageProps, E5WelcomePageState> {

	// ---------------- INIT ----------------

	//E5
	constructor(props: RouteComponentProps<{}>&E5WelcomePageProps, state: E5WelcomePageState) {
		super(props, state);
		this.state = {subpage: this.props.activePage || E5WelcomeSubPage.home};
	}

	componentDidUpdate(prevProps:E5WelcomePageProps){
		if(prevProps.activePage !== this.props.activePage){
			this.setState({subpage:this.props.activePage || E5WelcomeSubPage.home})
		}
	}
	// ---------------- RENDER ----------------

	//E5
	render(): JSX.Element {
		return <div className="e5page e5welcome-page e5columnfull e5column-0">
			{this.state.subpage === E5WelcomeSubPage.home && <div className="e5line-0 ">
				<div className="e5welcomecompo narrow info e5column-20">
					<div className="title center">{E5UtilI18n._("help")}</div>
					<div className="e5line-0 top-container">
						<HelpIcon className='help-icon'/>
						<div className='text-container'>
							<div className="subtitle welcometitle">{ E5UtilI18n._("home-cosmos") }</div>
							<br/>
								<div className="description">
									{ E5UtilI18n._("home-cosmos-description-1") }
								</div>
							<br/>
								<div className="description">
									{ E5UtilI18n._("home-cosmos-description-2") }
								</div>
							<br/>
						</div>
					</div>
					<div className="e5welcomecompo e5column-20">
						<div className="e5line-0">
							<div className="e5linefull"/>
							<div className="card info e5column-20">
								<div className="e5line-0"/>
								<div>
									<div className="subtitle info">{ E5UtilI18n._("home-connect-cpes") }</div>
									<div className="subtext">
										{ E5UtilI18n._("home-cpes-platform") }&nbsp;
										{this.RenderLink(E5Page.CB_DASHBOARD, E5UtilI18n._("home-customer-base"))}{ E5UtilI18n._("home-comma") }&nbsp;
										{this.RenderLink(E5Page.H_DASHBOARD, E5UtilI18n._("home-household"))}&nbsp;{ E5UtilI18n._("home-and") }&nbsp;
										{this.RenderLink(E5Page.H_METRICS, E5UtilI18n._("home-Metrics"))}.
									</div>
								</div>
							</div>
							<div className="e5linefull"/>
							<div className="e5linefull"/>
							<div className="card info e5column-20">
								<div className="e5line-0"/>
								<div>
									<div className="subtitle info">{ E5UtilI18n._("home-monitor-wiFi-networks") }</div>
									<div className="subtext">
										{ E5UtilI18n._("home-households-using") }&nbsp;
										{this.RenderLink(E5Page.H_DASHBOARD, E5UtilI18n._("home-dashboard"))}
										&nbsp;{ E5UtilI18n._("home-page-health-score") }&nbsp;
										{this.RenderLink(E5Page.H_METRICS, E5UtilI18n._("home-Metrics"))}
										.
									</div>
								</div>
							</div>
							<div className="e5linefull"/>
							<div className="e5linefull"/>
							<div className="card info e5column-20">
								<div className="e5line-0"/>
								<div>
									<div className="subtitle info">{ E5UtilI18n._("home-customer-base-analytics") }</div>
									<div className="subtext">
									{ E5UtilI18n._("home-monitor-deployment") }&nbsp;
										{this.RenderLink(E5Page.CB_DASHBOARD, E5UtilI18n._("home-customer-base"))}&nbsp;
										{ E5UtilI18n._("home-target-populations") }
									</div>
								</div>
							</div>
							<div className="e5linefull"/>
						</div>
						<br/>
						<div className="e5line-0">
							<div className="e5linefull"/>
							<div className="card info e5column-20">
								<div className="e5line-0"/>
								<div>
									<div className="subtitle info">{ E5UtilI18n._("home-manage-users-roles") }</div>
									<div className="subtext users">
									{ E5UtilI18n._("home-create-manage") }&nbsp;
										{this.RenderLink(E5Page.MANAGEMENT_USERS, E5UtilI18n._("home-user"))}&nbsp;
										{ E5UtilI18n._("home-allowed-connect") }&nbsp;<E5AdminUsersRolesLegend langinfo={E5Store.Ins().langinfo} text={""}/>
										.
									</div>
								</div>
							</div>
							<div className="e5linefull"/>
							<div className="e5linefull"/>
							<div className="card info e5column-20">
								<div className="e5line-0"/>
								<div>
									<div className="subtitle info">{ E5UtilI18n._("home-act-upon-incidents") }</div>
									<div className="subtext">
										{ E5UtilI18n._("home-optimize-quality") }&nbsp;
										{this.RenderLink(E5Page.H_INCIDENTS, E5UtilI18n._("home-incidents"))}
										&nbsp;{ E5UtilI18n._("home-proactively-acting") }&nbsp;
										{this.RenderLink(E5Page.H_INCIDENTS, E5UtilI18n._("home-recommendations"))}
										{ E5UtilI18n._("home-générées") }.
									</div>
								</div>
							</div>
							<div className="e5linefull"/>
							<div className="e5linefull"/>
							<div className="card info e5column-20">
								<div className="e5line-0"/>
								<div>
									<div className="subtitle info">{ E5UtilI18n._("home-manage-populations") }</div>
									<div className="subtext">
									{ E5UtilI18n._("home-finitune-your") }&nbsp;
										{this.RenderLink(E5Page.CB_DASHBOARD, E5UtilI18n._("home-customer-base"))}&nbsp;
										{ E5UtilI18n._("home-analytics-target") }&nbsp;
										{this.RenderLink(E5Page.CB_POPULATIONS, E5UtilI18n._("home-populations"))}&nbsp;
										{ E5UtilI18n._("home-various-criteria") }
									</div>
								</div>
							</div>
							<div className="e5linefull"/>
						</div>
						<br/>
					</div>
				</div>
			</div>}
			{this.state.subpage === E5WelcomeSubPage.howitworks && <div className='page-wrapper'>
				<div className="e5welcomecompo e5column-20">
					<div className="title center">{ E5UtilI18n._("home-how-to-work") }</div>
					<div className="e5line-0 card-row">
						<div className="card small e5column-20">
							<div className="subtitle">{ E5UtilI18n._("home-data-collection") }</div>
							<div>
								{ E5UtilI18n._("home-data-collection-description") }
							</div>
						</div>
						<div className="card small e5column-20">
							<div className="subtitle">{ E5UtilI18n._("home-data-ingestion-aggregation") }</div>
							<div>
								{ E5UtilI18n._("home-data-ingestion-aggregation-description") }
							</div>
						</div>
						<div className="card small e5column-20">
							<div className="subtitle">{ E5UtilI18n._("home-anomaly-severity-scoring") }</div>
							<div>
								{ E5UtilI18n._("home-anomaly-severity-scoring-description") }
							</div>
						</div>
					</div>
					<div className="e5line-0 card-row">
						<div className="card small e5column-20">
							<div className="subtitle">{ E5UtilI18n._("home-health-scores") }</div>
							<div>
								{ E5UtilI18n._("home-health-scores-description") }
							</div>
						</div>
						<div className="card small e5column-20">
							<div className="subtitle">{ E5UtilI18n._("home-incidents-recommendations") }</div>
							<div>
								{ E5UtilI18n._("home-incidents-recommendations-description") }
							</div>
						</div>
						<div className="card small e5column-20">
							<div className="subtitle">{ E5UtilI18n._("home-customer-base-kpis") }</div>
							<div>
								{ E5UtilI18n._("home-customer-base-kpis-description") }
							</div>
						</div>
					</div>
				</div>
			</div>}
			{this.state.subpage === E5WelcomeSubPage.developers && <div>
				<div className="e5line-0">
					<div className="e5welcomecompo narrow e5column-20">
						<div className="title center">Developers</div>
						<div className="e5line-20 verticalcenter">
							<img src="/img/welcome/dev.jpeg" alt=""/>
							<div className="e5linefull e5column-20">
								<div>
									All the features of the CMS Platform and enabled solutions (ex. Whole Home Wi-Fi)
									are exposed to external developers via open HTTP REST/POST APIs. The list of
									available APIs could be downloaded to start integrating the CMS Platform with
									your existing applications.
								</div>
								<div className="e5line-20">
									<div className="e5linefull"/>
									<div className="e5linefull"/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>}
		</div>;
	}
	//E5
	RenderLink = (page: E5Page, title: string): JSX.Element => {
		let ret: JSX.Element = <span>{title}</span>;
		if (E5Path.IsAllowed(page))
			ret = <BP.Button className="e5linkbutton" minimal={true} text={title}
							 onClick={() => E5Store.Ins().ChangePage(page, this.props.history)}/>;
		return ret;
	};

	// ---------------- EVENTS ----------------

	//E5
	ChangeSubPage = (subpage: string): void => this.setState({subpage});

	//E5
	GetSwagger = (): string => {
		let url: string = E5MainConfig.GetBackendUrl();
		url = url.substr(0, url.length - 4);// removes "api/"
		return url + "swagger-ui.html"
	};
});
