import React, { useState } from 'react'


import { E5EquipInterf } from '../../../entity/household/topo/E5EntHEquip'
import {ReactComponent as ChevronUp} from "../../../assets/stations/chevron-up.svg"
import {ReactComponent as ChevronDown} from "../../../assets/stations/chevron-down.svg"
import { E5UtilI18n } from '../../../global/E5MainLang'

interface IProps {
    intrf:E5EquipInterf;
    index:number;
}

const E5HInterfaceItem:React.FC<IProps> = ({intrf,index}) => {

    const [showDetails,setShowDetails] = useState(false)

    const Chevron = showDetails ? ChevronUp : ChevronDown;


    return <div className={'interface-item'}>
        <div className='interface-item-header' onClick={()=>setShowDetails(prev=>!prev)}>
            <div className='interface-text-item'>
                <div className='interface-text-item-value'>{E5UtilI18n._("wifih-health-desc-interface")} {index}</div>
            </div>
            <div className='interface-text-item'>
                <div className='interface-text-item-label'>{E5UtilI18n._("interface-item-macaddr")} :</div>
                <div className='interface-text-item-value'>{intrf.macaddress}</div>
            </div>
            <div className='interface-text-item'>
                <div className='interface-text-item-label'>{E5UtilI18n._("interface-item-band")} :</div>
                <div className='interface-text-item-value'>{intrf.band}</div>
            </div>
            <div className='interface-text-item'>
                <div className='interface-text-item-label'>{E5UtilI18n._("interface-item-status")} :</div>
                <div className='interface-text-item-value'>{intrf.status_as_is}</div>
            </div>
            <div className='interface-text-item'>
                <div className='interface-text-item-label'>{E5UtilI18n._("interface-item-maxBitRate")} :</div>
                <div className='interface-text-item-value'>{intrf.maxbitrate} Mb/s</div>
            </div>
            <Chevron className='interface-chevron'/>
        </div>
        {showDetails && <div className='interface-item-content'>
            <div className='interface-item-content-data'>
                <div className='interface-text-item'>
                    <div className='interface-text-item-label'>{E5UtilI18n._("interface-item-ssid")} :</div>
                    <div className='interface-text-item-value'>{intrf.wifissid}</div>
                </div>
            </div>
            <div className='interface-item-content-data'>
                <div className='content-data-label'>{E5UtilI18n._("interface-item-standards")} :</div>
                <div className='interface-text-item'>
                    <div className='interface-text-item-label'>{E5UtilI18n._("interface-item-available")} :</div>
                    <div className='interface-text-item-value'>{intrf.wifistdavailable.join(", ")}</div>
                </div>
                <div className='interface-text-item'>
                    <div className='interface-text-item-label'>{E5UtilI18n._("interface-item-used")} :</div>
                    <div className='interface-text-item-value'>{intrf.wifistdused.join(", ")}</div>
                </div>
            </div>
            <div className='interface-item-content-data'>
                <div className='content-data-label'>{E5UtilI18n._("interface-item-bandwidth")} :</div>
                <div className='interface-text-item'>
                    <div className='interface-text-item-label'>{E5UtilI18n._("interface-item-supported")} :</div>
                    <div className='interface-text-item-value'>{intrf.supportbandwidth}</div>
                </div>
                <div className='interface-text-item'>
                    <div className='interface-text-item-label'>{E5UtilI18n._("interface-item-current")} :</div>
                    <div className='interface-text-item-value'>{intrf.curbandwidth}</div>
                </div>
            </div>
            <div className='interface-item-content-data'>
                <div className='content-data-label'>{E5UtilI18n._("interface-item-channels")} :</div>
                <div className='interface-text-item'>
                    <div className='interface-text-item-label'>{E5UtilI18n._("interface-item-available")} :</div>
                    <div className='interface-text-item-value'>{intrf.possiblechannels}</div>
                </div>
                <div className='interface-text-item'>
                    <div className='interface-text-item-label'>{E5UtilI18n._("interface-item-used")} :</div>
                    <div className='interface-text-item-value'>{intrf.channelsinuse}</div>
                </div>
                <div className='interface-text-item'>
                    <div className='interface-text-item-label'>{E5UtilI18n._("interface-item-autoChannelEnable")} :</div>
                    <div className='interface-text-item-value'>{intrf.autochanenable.toString()}</div>
                </div>
            </div>
        </div>}
    </div>
}


export default E5HInterfaceItem;