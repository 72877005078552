import React, { useMemo, useState } from 'react';
import { E5BandEnum } from '../../../entity/E5Enums';
import { E5EntHIncidWifi } from '../../../entity/household/incid/E5EntHIncidWifi';
import { E5EntHEquip } from '../../../entity/household/topo/E5EntHEquip';
import { E5EntHStation } from '../../../entity/household/topo/E5EntHStation';
import { E5UtilI18n } from '../../../global/E5MainLang';
import { E5RequestMeta } from '../../../request/E5RequestMeta';
import { E5StoreLangInfo } from '../../../store/E5Store';
import { E5EntHIncidAny, E5StoreH, E5StoreHIncidents, E5StoreHIncidentSelIdx } from '../../../store/E5StoreH';
import { E5Text } from '../../../util/E5Text';

import { ReactComponent as ChevronUp } from "../../../assets/stations/chevron-up.svg"
import { ReactComponent as ChevronDown } from "../../../assets/stations/chevron-down.svg"

import './E5HIncidentItem.css'
import { E5XYNumData, E5XYSource, E5XYChart } from '../../../global/plot/E5XYChart';
import { E5MainConfig } from '../../../global/E5MainConfig';
import moment from 'moment';
import { E5HIncidSever } from '../../../entity/household/incid/E5EntHIncid';

interface IProps {
    langinfo: E5StoreLangInfo;
    incident: E5EntHIncidAny;
    indexinall: number;
    equipmap: Map<string, E5EntHEquip>;
    stationmap: Map<string, E5EntHStation>;
    incidentinfo: E5StoreHIncidents;
    selincidentinfo: E5StoreHIncidentSelIdx;
}


const bands = [
    E5BandEnum.freq2ghz,
    E5BandEnum.freq5ghz,
    E5BandEnum.freq6ghz,
    E5EntHIncidWifi.bandmultiple
]

const E5HIncidentItem: React.FC<IProps> = ({ incident, langinfo, indexinall, equipmap, stationmap, incidentinfo, selincidentinfo }) => {
    //@ts-ignore
    const { coredump, pid, priority, procname, reqdatestr, software, model_name } = incident;
    let formatedPid;
    if (pid && pid.length) {
        formatedPid = pid[0];
    }

    const { _ } = E5UtilI18n;
    const [showDetails, setShowDetails] = useState<number | null>(null)
    const [isSystemIncident, setIsSystemIncedent] = useState(false)
    const [isWANIncident, setIsWANIncident] = useState(false)
    const { id, criticality, imeis, duration, recoms } = incident;
    const macs = incident instanceof E5EntHIncidWifi ? incident.macs : [];
    const incidentName = (id.startsWith(E5RequestMeta.h_inc_sys_nb) ?
        (" " + _("system")) : (id.startsWith(E5RequestMeta.h_inc_wan_nb) ? " WAN" :
            " Wi-Fi " + (incident instanceof E5EntHIncidWifi && incident.isbackhaul ?
                "Backhaul" : "Fronthaul")));
    const incidentReason = _("meta-wifi-incident-code-" + id) || id;
    const incidentType = _("meta-wifi-incidentcateg-code-" + id.substr(0, E5RequestMeta.Ins().h_incidentcateglen))
    const criticalityInMinutes = E5Text.Seconds2Minutes(criticality);
    const generateColorBycriticalityInMinutes = (criticalityInMinutes: number) => {
        switch (true) {
            case (criticalityInMinutes === 0):
                return '#ffffff';
            case (criticalityInMinutes <= 10):
                return '#f1c134';
            case (criticalityInMinutes <= 20):
                return '#efb730';
            case (criticalityInMinutes <= 30):
                return '#ecaa33';
            case (criticalityInMinutes <= 40):
                return '#e99e38';
            case (criticalityInMinutes <= 50):
                return '#e59237';
            case (criticalityInMinutes <= 60):
                return '#d67231';
            case (criticalityInMinutes <= 70):
                return '#c75921';
            case (criticalityInMinutes <= 80):
                return '#b73816';
            case (criticalityInMinutes <= 90):
                return '#aa1d0c';
            case (criticalityInMinutes <= 100 || criticalityInMinutes >= 100):
                return '#991301';
            default:
                return '#ffffff';
        }
    }

    const incidentCriticality = E5Text.Seconds2DHMS_str(criticality)
    const incidentDuration: string = E5Text.Seconds2DHMS_str(duration);
    const incidentSeverity: string = E5Text.ScoreToPercent(criticality / duration) + " %";
    const incidentBand = incident instanceof E5EntHIncidWifi && bands.includes(incident.band)
        ? _(incident.band) : _("bandunknown");

    const equipList = useMemo(() => {
        return imeis.map((imei) => {
            const equip = equipmap.get(imei)
            return equip ? _("nodetype-" + equip.nodetype) + " " + imei + " (" + equip.model + ")" : imei;
        })
    }, [imeis])

    const stationList = useMemo(() => {
        return macs.map((addr) => {
            const station = stationmap.get(addr);
            return station ? station.hostname + " " + addr + `${station.devicetype ? `(${station.devicetype})` : ''}` : addr
        });
    }, [macs])


    let leftsource: E5XYSource = { numdatas: [{ xaxisdata: [], yaxisdata: [] }], options: { rangemode: "tozero", markers: true } };
    let selincidid: number = indexinall;
    let incidents: E5EntHIncidAny[] | undefined = incidentinfo.incidentmap.get(incidentinfo.seldatestr);
    if (selincidid >= 0 && incidents !== undefined && selincidid < incidents.length) {
        let incident: E5EntHIncidAny = incidents[selincidid];
        let anomids: string[] | undefined = E5RequestMeta.Ins().h_anomalymap.get(incident.id);
        let idx: number, anomstr: string = "";
        if (anomids !== undefined) {
            for (idx = 0; idx < anomids.length; idx++) {
                if (idx > 0) anomstr += ", ";
                anomstr += anomids[idx] + " (" + _("wifih-metrics-name-s" + anomids[idx]) + ")";
            }
        }

        for (idx = 0; idx < incident.severs.length; idx++) {
            let score: E5HIncidSever = incident.severs[idx];
            leftsource.numdatas?.[0].xaxisdata.push(score.time.format());
            leftsource.numdatas?.[0].yaxisdata.push(score.severity);
        }
    }

    const { incidentmap } = incidentinfo;
    const src: E5XYSource = { numdatas: undefined };
    src.numdatas = [];
    src.options = { stacked: false, bar: true, barstack: true, xtickvals: [] };
    if (incidentmap.size === 7) for (let idx = 0; idx < 7; idx++) {
        let datestr: string = moment().subtract(1 + idx, "day").format("YYYY-MM-DD");
        src.options.xtickvals?.push(datestr);
    }
    let incmap: Map<string, E5XYNumData> = new Map();
    for (let [datestr, incids] of incidentmap) {
        let inccritmap: Map<string, number> = new Map();
        for (let incid of incids) {
            if (incid.id === "" || incid.id === id) {
                let crit: number | undefined = inccritmap.get(incid.id);
                if (crit === undefined) crit = 0;
                crit += incid.criticality;
                inccritmap.set(incid.id, crit);
            }
        }
        for (let [incidid, critical] of inccritmap) {
            let numdata: E5XYNumData | undefined = incmap.get(incidid);
            if (numdata === undefined) {
                numdata = { xaxisdata: [], yaxisdata: [], datalabel: "", text: [] };
                numdata.datalabel = incidid;
                numdata.fillcolor = E5MainConfig.GetIncidentColors()[incidid];
                numdata.hovertemplate = "%{text} (%{x})<extra>%{fullData.name}</extra>";
                if (incidid === "") numdata.hidelegend = true;
                incmap.set(incidid, numdata);
            }
            numdata.xaxisdata.push(datestr);
            numdata.yaxisdata.push(critical / 3600);
            numdata.text?.push(E5Text.Seconds2DHMS_str(critical));
        }
    }
    for (let [, numdata] of incmap) src.numdatas.push(numdata);


    const getChevron = (id: number) => {
        const Chevron = id === showDetails ? ChevronUp : ChevronDown;
        return <Chevron className='equip-chevron' />
    }

    const handleToggle = (id: number) => {
        setShowDetails(prev => prev === id ? null : id)
    }

    if (incidentName === ' System' && isSystemIncident !== true) {
        setIsSystemIncedent(true)
    }

    if (incidentName === ' WAN' && isWANIncident !== true) {
        setIsWANIncident(true)
    }

    return <div className='incident-item'>
        <div className='incident-item-header'>
            <div className='incident-title-item'>
                <div className='incident-title-item-label'>{E5UtilI18n._("wifih-incident-tab-incident-one")} :</div>
                <div className='incident-title-item-content'>{incidentName}</div>
            </div>
            <div className='incident-title-item' >
                <div className='incident-criticality-wrapper'>
                    <div className='incident-title-item-label'>{E5UtilI18n._("wifih-incident-desc-criticality")} :</div>
                    <div className='incident-criticality'
                        style={{
                            backgroundColor: generateColorBycriticalityInMinutes(criticalityInMinutes),
                        }}></div>
                </div>
            </div>
            <div className='incident-title-item'>
                <div className='incident-title-item-label'>{E5UtilI18n._("admin-rdm-statusstatusreason")} :</div>
                <div className='incident-title-item-content'>{incidentReason}</div>
            </div>
            <div className='incident-title-item'>
                <div className='incident-title-item-label'>{E5UtilI18n._("wifih-incident-type-filter")} :</div>
                <div className='incident-title-item-content'>{incidentType}</div>
            </div>
        </div>
        <div className='indident-item-content'>
            <div className='incident-item-content-top'>
                <div className='incident-content-item'>
                    <div className='indident-content-item-title'>{imeis.length} {E5UtilI18n._("wifih-incident-equipement")} :</div>
                    {equipList.map(equip => <div className='incident-list-value'>{equip}</div>)}
                </div>
                {!isSystemIncident && <div className='incident-content-item'>
                    <div className='indident-content-item-title'>{macs.length} {E5UtilI18n._("wifih-incident-station")} :</div>
                    {stationList.map(station => <div className='incident-list-value'>{station}</div>)}
                </div>}
                <div className='incident-content-item small'>
                    <div className='indident-content-item-title'>{E5UtilI18n._("wifih-incident-desc-details")}:</div>
                    <div className='incident-title-item'>
                        <div className='incident-title-item-label'>{E5UtilI18n._("wifih-incident-desc-duration")}:</div>
                        <div className='incident-title-item-content'>{incidentDuration}</div>
                    </div>
                    <div className='incident-title-item'>
                        <div className='incident-title-item-label'>{E5UtilI18n._("wifih-incident-desc-severity")}:</div>
                        <div className='incident-title-item-content'>{incidentSeverity}</div>
                    </div>
                    {(!isSystemIncident && !isWANIncident) && <div className='incident-title-item'>
                        <div className='incident-title-item-label'>{E5UtilI18n._("interface-item-band")}:</div>
                        <div className='incident-title-item-content'>{incidentBand}</div>
                    </div>}
                    {model_name && <div className='incident-title-item'>
                        <div className='incident-title-item-label'>{E5UtilI18n._("wifih-incident-model-name")}:</div>
                        <div className='incident-title-item-content'>{model_name}</div>
                    </div>}
                    {formatedPid && <div className='incident-title-item'>
                        <div className='incident-title-item-label'>{E5UtilI18n._("wifih-incident-desc-pid")}:</div>
                        <div className='incident-title-item-content'>{formatedPid}</div>
                    </div>}

                    {priority && <div className='incident-title-item'>
                        <div className='incident-title-item-label'>{E5UtilI18n._("h-equip-sys-proc-priority")}:</div>
                        <div className='incident-title-item-content'>{priority}</div>
                    </div>}
                    {procname && <div className='incident-title-item'>
                        <div className='incident-title-item-label'>{E5UtilI18n._("wifih-incident-process-name")}:</div>
                        <div className='incident-title-item-content'>{procname}</div>
                    </div>}
                    {/* {reqdatestr && <div className='incident-title-item'>
                        <div className='incident-title-item-label'>Reqdatestr :</div>
                        <div className='incident-title-item-content'>{reqdatestr}</div>
                    </div>}
                    {software && <div className='incident-title-item'>
                        <div className='incident-title-item-label'>Software :</div>
                        <div className='incident-title-item-content'>{software}</div>
                    </div>} */}
                </div>
            </div>
            <div className='incident-item-content-bot'>
                <div className='incident-item-content-toggle' onClick={() => handleToggle(0)}>
                    {getChevron(0)}
                    {E5UtilI18n._("wifih-incident-recommendations")}
                </div>
                <div className='incident-item-content-toggle' onClick={() => handleToggle(1)}>
                    {getChevron(1)}
                    {E5UtilI18n._("wifih-incident-charts")}
                </div>
            </div>
            {showDetails !== null && <div className='incident-content-details'>
                {showDetails === 0 && <div className='recommendations-container'>
                    <div className='recommendation-item'>
                        <div className='indident-content-item-title'>{recoms.length} {E5UtilI18n._("wifih-incident-recommendations")} :</div>
                        {recoms.map((recom: any) => <div className='incident-title-item'>
                            <div className='incident-title-item-content'>{_("meta-wifi-recom-code-" + recom.id)}</div>
                        </div>)}
                    </div>
                    <div className='recommendation-item'>
                        <div className='indident-content-item-title'>{E5UtilI18n._("wifih-incident-context-infomation")} :</div>
                        {recoms.map((recom: any) => recom.contexts.map((context: any) =>
                            <div className='incident-title-item'>
                                <div className='incident-title-item-content'>{_("meta-wifi-recom-context-code-" + context)}</div>
                            </div>
                        ))}
                    </div>
                </div>}
                {showDetails === 1 && <div className='charts-container'>
                    <E5XYChart leftsource={leftsource} rightsource={{}} title={_("wifih-incident-graphtitle")}
                        loading={E5StoreH.Ins().incidentinfo.loading} height={200}
                        withNewComponent chartOption={{ withoutSymbol: true }} />
                </div>}
            </div>}
        </div>
    </div>
}

export default E5HIncidentItem;