import React, { useMemo } from 'react';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import './E5Table.css'
import { E5UtilI18n } from '../E5MainLang';
import { Checkbox } from '@mui/material';

interface ITableProps {
    columns: any;
    rows: any;
    className: string;
    translation?: string;
    withoutTranslation?: boolean
    getActions?: any;
}

const Checked = () => <><img src='./img/v3/checked.svg' alt="Account unlocked" /> </>;
const Unchecked = () => <><img src='./img/v3/unchecked.svg' alt="Account unlocked" /> </>;

const Table: React.FC<any> = ({ onCellClick, columns, rows, className, translation, withoutTranslation, getActions, onRowClick, onRowSelectionModelChange, onRowDoubleClick, width, keepNonExistentRowsSelected, rowSelectionModel, checkboxSelection, columnVisibilityModel, rowHeight, pageSize, firstColumnName, sortMode }) => {
    const columnsTable: GridColDef[] = useMemo(() => {
        return columns.map((value: any) => {
            if (value === 'actions') {
                return {
                    field: 'actions',
                    type: 'actions',
                    headerName: 'Household id',
                    width: 150,
                    getActions: ({ id }) => { return [getActions(id)] }
                }
            }
            if (typeof value === 'object') {
                let width = value.width;
                const column = { ...value, editable: false };
                if (!width) {
                    column.flex = 1
                } else {
                    column.width = width;
                }
                return column;
            }
        })
    }, [columns, translation, withoutTranslation])

    const rowsTable = useMemo(() => rows ? rows : [], [rows]);

    if (!pageSize) {
        pageSize = 10;
    }

    if (!rowsTable?.length) {
        return null;
    }
    return <div className='table'>
        <DataGrid
            columnVisibilityModel={columnVisibilityModel}
            onCellClick={onCellClick}
            onRowClick={onRowClick}
            onRowDoubleClick={onRowDoubleClick}
            rowHeight={rowHeight ?? 30}
            columnHeaderHeight={40}
            columns={columnsTable}
            rows={rowsTable}
            onRowSelectionModelChange={onRowSelectionModelChange}
            checkboxSelection={checkboxSelection}
            disableRowSelectionOnClick
            keepNonExistentRowsSelected={keepNonExistentRowsSelected}
            rowSelectionModel={rowSelectionModel}
            getRowHeight={() => 'auto'}
            sx={{
                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                    //display: "none"
                },
                "& .MuiDataGrid-cell": {
                    borderRight: '1px solid #EAEDF1',
                    padding: '3px 10px 3px 10px',
                    wordBreak: 'break-word',
                    whiteSpace: 'wrap',
                },
                '&  .MuiDataGrid-cellCheckbox': {
                    borderRight: 'none'
                }
            }}
            components={{
                BaseCheckbox: (props) => (
                    <Checkbox {...props} ssa='' checkedIcon={<Checked />} icon={<Unchecked />} />
                ),
            }}
            initialState={{
                pagination: {
                    paginationModel: { 
                        page: 0, 
                        pageSize: pageSize 
                    },
                },
                sorting: {
                    sortModel: [{ field: firstColumnName, sort: sortMode }],
                },
            }}
            pageSizeOptions={[10, 25, 50, 100]} />
    </div>
}

export default Table;