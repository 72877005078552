import cn from 'classnames'
import { observer } from 'mobx-react';
import { useState } from 'react';
import { E5UtilI18n } from '../../../global/E5MainLang';
import ReactECharts from 'echarts-for-react';
import { E5Store } from '../../../store/E5Store';
import { E5StoreHActivityDevices } from '../../../store/E5StoreH';
import { devicesAcitivityBarOptions, devicesActivityDetectionDayOptions, devicesActivityDetectionWeekOptions, devicesActivityDonutOptions, devicesCountOptions, parseActivityDetectionDay, parseActivityDetectionWeek, parseDataForBarDonutGraph } from '../../../util/E5HActivityUtils';
import './E5HActivity.css';
import { Spinner } from '@blueprintjs/core';
import { E5RequestH } from '../../../request/E5RequestH';

interface E5HActivityProps {
    activityDeviceWeek: E5StoreHActivityDevices,
    activityDeviceDay: E5StoreHActivityDevices
}

export const E5HActivity = observer(function E5HActivity(props: E5HActivityProps) {
    let curlang = E5Store.Ins().langinfo.curlang; //eslint-disable-line

    const [viewMode, setViewMode] = useState("day");
    const [mostActiveDeviceViewMode, setMostActiveDeviceViewMode] = useState("wifi");
    const [deviceCountMode, setDeviceCountMode] = useState("active");

    const updateWeekMode = () => {
        setViewMode(viewMode === "week" ? "day" : "week");
        
        if (localStorage.getItem('networkid') !== null) {
            let networkid = localStorage.getItem('networkid') as string;
            E5RequestH.Ins().FetchActivityDevices(networkid, undefined, "week");
            localStorage.removeItem('networkid');
        }
    };

    const updateMostActiveDevice = () => {
        setMostActiveDeviceViewMode(mostActiveDeviceViewMode === "wifi" ? "eth" : "wifi");
    };

    const updateDeviceCountMode = () => {
        setDeviceCountMode(deviceCountMode === "active" ? "all" : "active");
    };

    const dayGraphs = () => {
        return (
            <>
                <div className="e5hactivity-boxes-spacement e5hactivity-grid-two-columns">
                    {devicesActivityDayGraph()}
                    {devicesCountChart("h-dashboard-devices", props.activityDeviceDay)}
                </div>
                <div className="e5hactivity-boxes-spacement">
                    {barDonutGraphs("h-dashboard-most-active-devices", props.activityDeviceDay)}
                </div>

                <div className="e5hactivity-boxes-spacement">
                    {barDonutGraphs("h-dashboard-most-consuming-devices", props.activityDeviceDay, true)}
                </div>
            </>
        )
    };

    const weekGraphs = () => {
        return (
            <>
                <div className="e5hactivity-boxes-spacement">
                    {devicesActivityWeekGraph()}
                </div>

                <div className="e5hactivity-boxes-spacement">
                    {devicesCountChart("h-dashboard-devices", props.activityDeviceWeek)}
                </div>

                <div className="e5hactivity-boxes-spacement">
                    {barDonutGraphs("h-dashboard-most-active-devices", props.activityDeviceWeek)}
                </div>

                <div className="e5hactivity-boxes-spacement">
                    {barDonutGraphs("h-dashboard-most-consuming-devices", props.activityDeviceWeek, true)}
                </div>
            </>
        )
    }


    const devicesCountChart = (title: string, graphData: E5StoreHActivityDevices) => {
        let parsedGraphAllDevicesData = parseDataForBarDonutGraph(graphData.deviceCountAll, false);
        let parsedGraphActiveDevicesData = parseDataForBarDonutGraph(graphData.deviceCountActive, false);

        if (graphData.loading) {
            return (
                <div className="e5hactivity-box">
                    <span className="e5hactivity-graph-title">{E5UtilI18n._(title)}</span>
                    <div className="e5line-20 e5hloading">
                        <Spinner className="e5spinwait" size={30} />
                    </div>
                </div>
            )
        } else if (!parsedGraphAllDevicesData.length || !parsedGraphActiveDevicesData.length) {
            return (
                <div className="e5hactivity-box">
                    <span className="e5hactivity-graph-title">{E5UtilI18n._(title)}</span>
                    <div className="e5line-20">
                        <span className="no-data">{E5UtilI18n._(graphData.loading ? "wificb-dashboard-notfound" : "no-data-collected")}</span>
                    </div>
                </div>
            )
        } else {
            if (viewMode === 'day') {
                return (
                    <div className="e5hactivity-box">
                        <span className="e5hactivity-graph-title">{E5UtilI18n._(title)}</span>
                        <ReactECharts option={devicesCountOptions(deviceCountMode === 'active' ? parsedGraphActiveDevicesData : parsedGraphAllDevicesData)} />

                        <div className="e5line-20 device-count-filter">
                            <div className="e5line-0 toggle">
                                <div className={cn('toggle-item', { 'active': deviceCountMode === 'active' })} onClick={updateDeviceCountMode}>{E5UtilI18n._('h-dashboard-connected-devices')}</div>
                                <div className={cn('toggle-item', { 'active': deviceCountMode === 'all' })} onClick={updateDeviceCountMode}>{E5UtilI18n._('h-dashboard-all-devices')}</div>
                            </div>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="e5hactivity-box">
                        <span className="e5hactivity-graph-title">{E5UtilI18n._(title)}</span>
                        <div className="e5hactivity-grid-two-columns">
                            <div>
                                <ReactECharts option={devicesCountOptions(parsedGraphActiveDevicesData)} />
                                <span className="e5hactivity-graph-title">
                                    {E5UtilI18n._('h-dashboard-connected-devices')}
                                </span>
                            </div>
                            <div>
                                <ReactECharts option={devicesCountOptions(parsedGraphAllDevicesData)} />
                                <span className="e5hactivity-graph-title">
                                    {E5UtilI18n._('h-dashboard-all-devices')}
                                </span>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    };

    const barDonutGraphs = (title: string, graphData: E5StoreHActivityDevices, isMostConsuming = false) => {
        let parsedGraphData: { value: number; name: string; }[] = [];

        if (isMostConsuming) {
            parsedGraphData = parseDataForBarDonutGraph(graphData.mostConsumingDevices);
        } else {
            parsedGraphData = parseDataForBarDonutGraph(mostActiveDeviceViewMode === 'wifi' ? graphData.mostActiveDevicesWiFi : graphData.mostActiveDevicesEthernet)
        }

        if (graphData.loading) {
            return (
                <div className="e5hactivity-box">
                    <span className="e5hactivity-graph-title">{E5UtilI18n._(title)}</span>
                    <div className="e5line-20 e5hloading">
                        <Spinner className="e5spinwait" size={30} />
                    </div>
                </div>
            )
        } else if (!parsedGraphData.length) {
            return (
                <div className="e5hactivity-box">
                    {!isMostConsuming && !graphData.loading ? (
                        <div className="e5line-20 device-type-filter">
                            <div className="e5line-0 toggle">
                                <div className={cn('toggle-item', { 'active': mostActiveDeviceViewMode === 'wifi' })} onClick={updateMostActiveDevice}>Wi-Fi</div>
                                <div className={cn('toggle-item', { 'active': mostActiveDeviceViewMode === 'eth' })} onClick={updateMostActiveDevice}>ETH</div>
                            </div>
                        </div>
                    ) : null}

                    <span className="e5hactivity-graph-title">{E5UtilI18n._(title)}</span>
                    <div className="e5line-20">
                        <span className="no-data">{E5UtilI18n._(graphData.loading ? "wificb-dashboard-notfound" : "no-data-collected")}</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="e5hactivity-box">
                    {!isMostConsuming ? (
                        <div className="e5line-20 device-type-filter">
                            <div className="e5line-0 toggle">
                                <div className={cn('toggle-item', { 'active': mostActiveDeviceViewMode === 'wifi' })} onClick={updateMostActiveDevice}>Wi-Fi</div>
                                <div className={cn('toggle-item', { 'active': mostActiveDeviceViewMode === 'eth' })} onClick={updateMostActiveDevice}>ETH</div>
                            </div>
                        </div>
                    ) : null}

                    <span className="e5hactivity-graph-title">{E5UtilI18n._(title)}</span>

                    <div className="e5hbardonutgraph">
                        <ReactECharts option={devicesAcitivityBarOptions(parsedGraphData, isMostConsuming)} />
                        <ReactECharts option={devicesActivityDonutOptions(parsedGraphData, isMostConsuming)} />
                    </div>
                </div>
            )
        }
    };

    const devicesActivityDayGraph = () => {
        const activityDetectionDay = parseActivityDetectionDay(props.activityDeviceDay.activityDetection);

        if (props.activityDeviceDay.loading) {
            return (
                <div className="e5hactivity-box">
                    <span className="e5hactivity-graph-title">{E5UtilI18n._("cb-dashboard-device-activity")}</span>
                    <div className="e5line-20 e5hloading">
                        <Spinner className="e5spinwait" size={30} />
                    </div>
                </div>
            )
        } else if (!activityDetectionDay.length) {
            return (
                <div className="e5hactivity-box">
                    <span className="e5hactivity-graph-title">{E5UtilI18n._("cb-dashboard-device-activity")}</span>
                    <div className="e5line-20">
                        <span className="no-data">{E5UtilI18n._(props.activityDeviceDay.loading ? "wificb-dashboard-notfound" : "no-data-collected")}</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="e5hactivity-box">
                    <span className="e5hactivity-graph-title">{E5UtilI18n._("cb-dashboard-device-activity")}</span>
                    <div className="e5line-20">
                        <ReactECharts option={devicesActivityDetectionDayOptions(activityDetectionDay)} />
                    </div>

                    <div className="e5hactivity-legend e5hactivity-legend-centered">
                        <div className="e5hactivity-legend-activity-space e5hactivity-legend-spacement"></div>
                        
                        <div className="e5hactivity-legend e5hactivity-legend-spacement">
                            <div className="e5hactivity-legend-low-activity"></div>
                            <span>{E5UtilI18n._("h-dashboard-activity-low-activity")}</span>
                        </div>

                        <div className="e5hactivity-legend">
                            <div className="e5hactivity-legend-high-activity"></div>
                            <span>{E5UtilI18n._("h-dashboard-activity-high-activity")}</span>
                        </div>

                        <div className="e5hactivity-legend e5hactivity-legend-spacement">
                            <div className="e5hactivity-legend-notreceived"></div>
                            <span>{E5UtilI18n._("cb-dashboard-device-notreceived")}</span>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const devicesActivityWeekGraph = () => {
        const activityDetectionWeek = parseActivityDetectionWeek(props.activityDeviceWeek.activityDetection);

        if (props.activityDeviceWeek.loading) {
            return (
                <div className="e5hactivity-box">
                    <span className="e5hactivity-graph-title">{E5UtilI18n._("cb-dashboard-device-activity")}</span>
                    <div className="e5line-20 e5hloading">
                        <Spinner className="e5spinwait" size={30} />
                    </div>
                </div>
            )
        } else if (!activityDetectionWeek.length) {
            return (
                <div className="e5hactivity-box">
                    <span className="e5hactivity-graph-title">{E5UtilI18n._("cb-dashboard-device-activity")}</span>
                    <div className="e5line-20">
                        <span className="no-data">{E5UtilI18n._(props.activityDeviceWeek.loading ? "wificb-dashboard-notfound" : "no-data-collected")}</span>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="e5hactivity-box">
                    <span className="e5hactivity-graph-title">{E5UtilI18n._("cb-dashboard-device-activity")}</span>
                    <ReactECharts option={devicesActivityDetectionWeekOptions(activityDetectionWeek)} opts={{ renderer: 'svg' }}/>

                    <div className="e5hactivity-legend e5hactivity-boxes-spacement">
                        <span className="e5hactivity-graph-legend-title e5hactivity-legend-spacement">{E5UtilI18n._("cb-dashboard-device-mbsend")}</span>

                        <div className="e5hactivity-legend">
                            <span>{E5UtilI18n._("cb-dashboard-device-lowactivity")}</span>
                            <div className="e5hactivity-legend-usage e5hactivity-legend-spacement"></div>
                            <span>{E5UtilI18n._("cb-dashboard-device-highactivity")}</span>
                        </div>

                        <div className="e5hactivity-legend e5hactivity-legend-spacement">
                            <div className="e5hactivity-legend-notreceived"></div>
                            <span>{E5UtilI18n._("cb-dashboard-device-notreceived")}</span>
                        </div>
                    </div>
                </div>
            )
        }
    };

    return (
        <div className="e5h-activity-week-mode">
            <div className="e5line-20 date-filter">
                <div className="e5line-0 toggle">
                    <div className={cn('toggle-item', { 'active': viewMode === 'day' })} onClick={updateWeekMode}>{E5UtilI18n._('cb-dashboard-day')}</div>
                    <div className={cn('toggle-item', { 'active': viewMode === 'week' })} onClick={updateWeekMode}>{E5UtilI18n._('cb-dashboard-week')}</div>
                </div>
            </div>

            {viewMode === 'week' ? weekGraphs() : dayGraphs()}
        </div>
    )
})
