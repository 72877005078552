import Table from "../../../global/component/E5Table";
import { E5UtilI18n } from "../../../global/E5MainLang";
import { E5Text } from "../../../util/E5Text";
import { E5Time } from "../../../util/E5Time";

interface E5HEventLogsTableProps {
    data: any;
    eventType: string | undefined;
}

export default function E5HEventLogsTable({ data, eventType }: E5HEventLogsTableProps) {
    const headerTitles: { [key: string]: string } = {
        'Controller MAC Address': E5UtilI18n._("cb-eventlog-controller-mac-address"),
        'SerialNumber': E5UtilI18n._("cb-eventlog-serial-number"),
        'COLLECTION': E5UtilI18n._("cb-eventlog-collection"),
        'PARAMETER': E5UtilI18n._("cb-eventlog-parameter"),
        'EVT_NAME': E5UtilI18n._("cb-eventlog-event-name"),
        'STA_MAC': E5UtilI18n._("cb-eventlog-station-mac"),
        'AP_NAME': E5UtilI18n._("cb-eventlog-access-point"),
        'REASON': E5UtilI18n._("cb-eventlog-reason"),
        'BAND_FROM': E5UtilI18n._("cb-eventlog-previous-band"),
        'BAND_TO': E5UtilI18n._("cb-eventlog-new-band")
    };

    let columnsNames: any = [
        ...new Set(data.map((item: any) => Object.keys(item)).flat())
    ];

    const defaultColumns = {
        eventTimeColumn: {
            id: false,
            field: 'eventTIME',
            headerName: E5UtilI18n?._("cb-eventlog-event-time"),
            hide: true,
            editable: true,
            headerAlign: 'center',
            align: 'left',
            minWidth: 100,
            valueGetter: (params: any) => {
                return params.row.eventTime;
            },
            renderCell: (params: any) => {
                return params.row.eventTime;
            },
            sortComparator: (v1: any, v2: any) => {
                const timeToSeconds = (time: string): number => {
                    const [hours, minutes, seconds] = time.split(":").map(Number);
                    return hours * 3600 + minutes * 60 + seconds;
                };

                return timeToSeconds(v1) - timeToSeconds(v2);
            }
        },
        deviceImeiColumn: {
            field: 'deviceEmei',
            headerName: E5UtilI18n?._("cb-eventlog-device"),
            editable: true,
            headerAlign: 'center',
            align: 'left',
            minWidth: 150,
            valueGetter: (params: any) => {
                return params.row['Device IMEI'];
            },
            renderCell: (params: any) => {
                return params.row['Device IMEI'];
            },
            sortComparator: (v1: any, v2: any) => {
                return v1?.localeCompare(v2)
            }
        },
        eventNameColumn: {
            field: 'eventName',
            headerName: E5UtilI18n?._("cb-eventlog-event-name"),
            editable: true,
            headerAlign: 'center',
            align: 'left',
            minWidth: 100,
            valueGetter: (params: any) => {
                return params.row[E5UtilI18n?._("cb-eventlog-event-name")];
            },
            renderCell: (params: any) => {
                return params.row[E5UtilI18n?._("cb-eventlog-event-name")];
            },
            sortComparator: (v1: any, v2: any) => {
                return v1?.localeCompare(v2);
            }
        },
        macAddressColumn: {
            field: 'macAddress',
            headerName: E5UtilI18n?._("cb-eventlog-station-mac"),
            editable: true,
            headerAlign: 'center',
            align: 'center',
            minWidth: 150,
            valueGetter: (params: any) => {
                return params.row[E5UtilI18n?._("cb-eventlog-station-mac")];
            },
            renderCell: (params: any) => {
                return params.row[E5UtilI18n?._("cb-eventlog-station-mac")];
            },
            sortComparator: (v1: any, v2: any) => {
                return v1?.localeCompare(v2);
            }
        },
        accessPointColumn: {
            field: 'accessPoint',
            headerName: E5UtilI18n?._("cb-eventlog-access-point"),
            editable: true,
            headerAlign: 'center',
            align: 'left',
            minWidth: 150,
            valueGetter: (params: any) => {
                return params.row[E5UtilI18n?._("cb-eventlog-access-point")];
            },
            renderCell: (params: any) => {
                return params.row[E5UtilI18n?._("cb-eventlog-access-point")];
            },
            sortComparator: (v1: any, v2: any) => {
                return v1?.localeCompare(v2);
            }
        },
        reasonColumn: {
            field: 'reason',
            headerName: E5UtilI18n?._("cb-eventlog-reason"),
            editable: true,
            headerAlign: 'center',
            align: 'left',
            minWidth: 100,
            valueGetter: (params: any) => {
                return params.row[E5UtilI18n?._("cb-eventlog-reason")];
            },
            renderCell: (params: any) => {
                return params.row[E5UtilI18n?._("cb-eventlog-reason")];
            },
            sortComparator: (v1: any, v2: any) => {
                return v1?.localeCompare(v2);
            }
        },
        controllerMacAddressColumn: {
            field: 'Controller MAC Address',
            headerName: E5UtilI18n?._("cb-eventlog-controller-mac-address"),
            editable: true,
            headerAlign: 'center',
            align: 'left',
            minWidth: 100,
            valueGetter: (params: any) => {
                return params.row[E5UtilI18n?._("cb-eventlog-controller-mac-address")];
            },
            renderCell: (params: any) => {
                return params.row[E5UtilI18n?._("cb-eventlog-controller-mac-address")];
            },
            sortComparator: (v1: any, v2: any) => {
                return v1?.localeCompare(v2);
            }
        },
        serialNumberColumn: {
            field: 'SerialNumber',
            headerName: E5UtilI18n?._("cb-eventlog-serial-number"),
            editable: true,
            headerAlign: 'center',
            align: 'left',
            minWidth: 100,
            valueGetter: (params: any) => {
                return params.row[E5UtilI18n?._("cb-eventlog-serial-number")];
            },
            renderCell: (params: any) => {
                return params.row[E5UtilI18n?._("cb-eventlog-serial-number")];
            },
            sortComparator: (v1: any, v2: any) => {
                return v1?.localeCompare(v2);
            }
        },
        collectionColumn: {
            field: 'collection',
            headerName: E5UtilI18n?._("cb-eventlog-collection"),
            editable: true,
            headerAlign: 'center',
            align: 'left',
            minWidth: 100,
            valueGetter: (params: any) => {
                return params.row[E5UtilI18n?._("cb-eventlog-collection")];
            },
            renderCell: (params: any) => {
                return params.row[E5UtilI18n?._("cb-eventlog-collection")];
            },
            sortComparator: (v1: any, v2: any) => {
                return v1?.localeCompare(v2);
            }
        },
        parameterColumn: {
            field: 'parameter',
            headerName: E5UtilI18n?._("cb-eventlog-parameter"),
            editable: true,
            headerAlign: 'center',
            align: 'left',
            minWidth: 100,
            valueGetter: (params: any) => {
                return params.row[E5UtilI18n?._("cb-eventlog-parameter")];
            },
            renderCell: (params: any) => {
                return params.row[E5UtilI18n?._("cb-eventlog-parameter")];
            },
            sortComparator: (v1: any, v2: any) => {
                return v1?.localeCompare(v2);
            }
        },
    };

    const connectivityColumns = [
        defaultColumns.eventTimeColumn,
        defaultColumns.deviceImeiColumn,
        defaultColumns.eventNameColumn,
        defaultColumns.macAddressColumn,
        defaultColumns.accessPointColumn,
        defaultColumns.reasonColumn,
    ];

    const bandSteeringColumns = [
        defaultColumns.eventTimeColumn,
        defaultColumns.deviceImeiColumn,
        defaultColumns.eventNameColumn,
        defaultColumns.macAddressColumn,
    ];

    const meshInstabilityColumns = [
        defaultColumns.eventTimeColumn,
        defaultColumns.deviceImeiColumn,
        defaultColumns.eventNameColumn,
        defaultColumns.controllerMacAddressColumn,
        defaultColumns.serialNumberColumn,
    ];

    const missingDataColumns = [
        defaultColumns.eventTimeColumn,
        defaultColumns.deviceImeiColumn,
        defaultColumns.eventNameColumn,
        defaultColumns.collectionColumn,
        defaultColumns.parameterColumn,
        defaultColumns.reasonColumn,
    ];

    const returnEventColumns = (eventType: string | undefined) => {
        switch (eventType) {
            case '0':
                return connectivityColumns;
            case '1':
                return bandSteeringColumns;
            case '2':
                return meshInstabilityColumns;
            case '3':
                return missingDataColumns;
            default:
                return Object.values(defaultColumns);
        }
    }

    return (
        <div className="eventTable">
            <Table
                firstColumnName='eventTIME'
                sortMode='asc'
                pageSize={25}
                checkboxSelection={false}
                className=''
                columns={returnEventColumns(eventType)}
                rows={[
                    ...data.map((item: any, index: number) => {
                        const result: any = {};
                        columnsNames.forEach((name: string | number) => {
                            result[headerTitles[name]] = item[name] || '-'
                        });

                        return ({
                            ...result,
                            eventTime: E5Text.Tm2MomentTz(item.eventTime, E5Time.h_tzoffset).format('HH:mm:ss'),
                            'Device IMEI': item.deviceImei,
                            id: index
                        });
                    })
                ]}
            />
        </div>
    )
}